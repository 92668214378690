//@author: devin
import { s25Dateparser } from "../modules/s25-dateformat/s25.dateparser.service";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { S25Dateformat } from "../modules/s25-dateformat/s25.dateformat.service";
import { S25Const } from "./s25-const";
import { jSith } from "./jquery-replacement";
import { DurationI } from "../pojo/DurationI";
import { ChangeDetectorRef } from "@angular/core";
import { S25WsNode } from "../pojo/S25WsNode";
import { DeepOmit, NestedArray, PickByType, ValueElseUndefined } from "../pojo/Util";
import { MemoOptions, MemoRepository } from "../decorators/memo.decorator";
import { Proto } from "../pojo/Proto";
import { EventSummary } from "../modules/s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;
import ISODateString = Proto.ISODateString;
import ISOTimeString = Proto.ISOTimeString;
import Result = Proto.Result;

declare global {
    interface Window {
        X2JS: any;
        DOMParser: any;
        angBridge: any;
        ProData: any;
        opera: any;
        dayjs: any;
        $q: any;
        $timeout: any;
    }
}

export class S25Util {
    public static viewportWidth: number = jSith.width(window);

    public static X2JS_INSTANCE: any = null;

    public static ScriptPromises: Map<string, Promise<boolean>> = new Map<string, Promise<boolean>>();

    public static IsAppleMail: boolean = undefined;

    public static skipLoginInterval = false;

    private static GET_X2JS_INSTANCE(): any {
        if (S25Util.X2JS_INSTANCE) {
            return S25Util.X2JS_INSTANCE;
        } else {
            S25Util.X2JS_INSTANCE = new window.X2JS({});
            return S25Util.X2JS_INSTANCE;
        }
    }

    public static extractReservationDateTimes(reservation: any) {
        //reservation node from WS
        let rsrvStartDt = S25Util.date.parse(reservation.reservation_start_dt);
        let preStartDt = S25Util.date.parse(reservation.pre_event_dt || reservation.reservation_start_dt);
        let evStartDt = S25Util.date.parse(reservation.event_start_dt);
        let evEndDt = S25Util.date.parse(reservation.event_end_dt);
        let postEndDt = S25Util.date.parse(reservation.post_event_dt || reservation.event_end_dt);
        let rsrvEndDt = S25Util.date.parse(reservation.reservation_end_dt);

        var ret: any = {
            rsrvStartDt: rsrvStartDt,

            setupStartDt: rsrvStartDt,
            setupEndDt: preStartDt,

            preStartDt: preStartDt,
            preEndDt: evStartDt,

            evStartDt: evStartDt,
            evEndDt: evEndDt,

            postStartDt: evEndDt,
            postEndDt: postEndDt,

            takedownStartDt: postEndDt,
            takedownEndDt: rsrvEndDt,

            rsrvEndDt: rsrvEndDt,

            hasSetup: !S25Util.date.equal(rsrvStartDt, preStartDt),
            hasPre: !S25Util.date.equal(preStartDt, evStartDt),
            hasPost: !S25Util.date.equal(evEndDt, postEndDt),
            hasTakedown: !S25Util.date.equal(postEndDt, rsrvEndDt),
        };

        S25Util.extend(ret, {
            setupEvStartDiffDay: ret.hasSetup && !S25Util.date.equalDate(ret.setupStartDt, ret.evStartDt),
            takedownEvEndDiffDay: ret.hasTakedown && !S25Util.date.equalDate(ret.takedownStartDt, ret.evEndDt),

            setupStartEndDiffDay: ret.hasSetup && !S25Util.date.equalDate(ret.setupStartDt, ret.setupEndDt),
            takedownStartEndDiffDay: ret.hasTakedown && !S25Util.date.equalDate(ret.takedownStartDt, ret.takedownEndDt),
        });

        return ret;
    }

    public static getBlobFromUrl(url: string) {
        let defer = jSith.defer();

        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();

            reader.onloadend = function () {
                defer.resolve(reader.result);
            };

            reader.onerror = function () {
                defer.resolve(null);
            };

            reader.onabort = function () {
                defer.resolve(null);
            };

            if (xhr.status >= 300) {
                defer.resolve(null);
            } else {
                reader.readAsDataURL(xhr.response);
            }
        };

        xhr.onerror = function () {
            defer.resolve(null);
        };

        xhr.onabort = function () {
            defer.resolve(null);
        };

        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();

        return defer.promise;
    }

    public static toDataUri(url: string, replSource?: string, replTarget?: string): any {
        replSource = replSource || "https://";
        replTarget = replTarget || "http://";
        return S25Util.getBlobFromUrl(url).then(function (resp) {
            //get blob from url
            if (resp) {
                //return blob
                return resp;
            } else {
                //no blob found
                if (url.indexOf(replSource) > -1) {
                    //try again, replacing source w/ target
                    url = url.replace(replSource, replTarget);
                    return S25Util.toDataUri(url);
                }
                return null;
            }
        });
    }

    public static getPayload(
        rootName: string,
        itemNodeName: string,
        idNodeName: string,
        status: string,
        itemId: number | string,
        objExtend: any,
    ) {
        let payload: any = {};
        if (rootName) {
            let item: any = {};
            item[idNodeName] = itemId;
            item = Object.assign(item, objExtend || {});
            item.status = status;

            payload[rootName] = {};
            payload[rootName][itemNodeName] = item;
        }
        return payload;
    }

    public static all(obj: any) {
        if (S25Util.array.isArray(obj)) {
            return Promise.all(obj);
        } else if (S25Util.isObject(obj)) {
            let arr: any[] = S25Util.array.propertyListToArray(obj);
            let arrP: any[] = arr.map((e: any) => e.value);
            return Promise.all(arrP).then((resp: any[]) => {
                let objP: any = {};
                for (let i = 0; i < resp.length; i++) {
                    objP[arr[i].prop] = resp[i];
                }
                return objP;
            });
        } else {
            return Promise.resolve(obj);
        }
    }

    public static any(promiseArr: Promise<any>[]) {
        return Promise.allSettled(promiseArr).then((respArr) => {
            if (respArr?.length && respArr.every((resp) => resp.status === "rejected")) {
                return jSith.reject(respArr);
            } else {
                return jSith.resolve(respArr);
            }
        });
    }

    public static batch(
        batchFunc: (idx: number) => Promise<any>,
        batchSize: number,
        total: number,
        dynamicBatchSizeThresholdMs?: number,
        thresholdRangeMs?: number,
        hi?: number,
        lo?: number,
        currIdx?: number,
        promises?: Promise<any>[],
    ): Promise<any> {
        currIdx = currIdx || 0;
        promises = promises || [];
        batchSize = batchSize || 1;
        hi = hi || batchSize;
        lo = lo || batchSize;
        thresholdRangeMs = thresholdRangeMs || 500;

        for (let i = 0; i < batchSize; i++) {
            if (currIdx < total) {
                promises.push(batchFunc(currIdx));
                currIdx++;
            } else {
                return S25Util.all(promises);
            }
        }

        let start = Date.now();
        return S25Util.all(promises).then(() => {
            if (dynamicBatchSizeThresholdMs) {
                let elapsedMs = Date.now() - start;

                //we are under the threshold by a significant amount (the threshold range amount) -- we can increase batch size
                if (
                    elapsedMs < dynamicBatchSizeThresholdMs &&
                    dynamicBatchSizeThresholdMs - elapsedMs > thresholdRangeMs
                ) {
                    lo = batchSize; //low boundary is now the current batch size
                    hi = hi * 2; //double the high boundary to continue searching
                    batchSize = Math.ceil((hi + lo) / 2); //new batch size is the mid value (ceil to ramp up quicker)
                } else if (
                    elapsedMs > dynamicBatchSizeThresholdMs &&
                    elapsedMs - dynamicBatchSizeThresholdMs > thresholdRangeMs
                ) {
                    //we are over the threshold by a significant amount (the threshold range amount) -- we must decrease batch size

                    //high and low are the same, but we are still over the threshold. So low should go down bc the server, perhaps, has become overloaded
                    if (hi === lo) {
                        lo = Math.max(Math.floor(lo / 2), 1);
                    }

                    hi = batchSize; //high boundary is now the current batch size while low boundary remains the same (or was updated above)

                    batchSize = Math.max(Math.floor((hi + lo) / 2), 1); //new batch size is the mid value (floor to ramp down quicker)
                }
            }

            return S25Util.batch(
                batchFunc,
                batchSize,
                total,
                dynamicBatchSizeThresholdMs,
                thresholdRangeMs,
                hi,
                lo,
                currIdx,
                promises,
            );
        });
    }

    private static isMobile: boolean = undefined;

    public static getMultiQuery(searchQuery: string, multiQueryStr: string): string {
        searchQuery = S25Util.toStr(searchQuery);
        searchQuery = searchQuery.replace(/&events*_query_id=\d+/gi, "");
        searchQuery = searchQuery.replace(/&spaces*_query_id=\d+/gi, "");
        searchQuery = searchQuery.replace(/&resources*_query_id=\d+/gi, "");
        searchQuery = searchQuery.replace(/&organizations*_query_id=\d+/gi, "");
        searchQuery = searchQuery.replace(/&contacts*_query_id=\d+/gi, "");
        searchQuery = searchQuery.replace(/&query_id=\d+/gi, "");
        searchQuery += "&multi_query_id=" + multiQueryStr;
        return searchQuery;
    }

    public static getObjQueryId(itemTypeId: string) {
        let searchQuery;
        switch (itemTypeId) {
            case "1":
            case "event":
                searchQuery = "event_query_id=";
                break;
            case "2":
            case "organization":
                searchQuery = "organization_query_id=";
                break;
            case "4":
            case "location":
                searchQuery = "space_query_id=";
                break;
            case "6":
            case "resource":
                searchQuery = "resource_query_id=";
                break;
        }

        return searchQuery;
    }

    public static camelToOther(str: string, delim: string): string {
        return str
            .replace(/[\w]([A-Z])/g, function (m) {
                return m[0] + delim + m[1];
            })
            .toLowerCase();
    }

    public static otherToCamel(str: string, delim: string): string {
        let pat = "(" + delim + "\\w)";
        let reg = new RegExp(pat, "g");
        return str.replace(reg, function (m) {
            return m[1].toUpperCase();
        });
    }

    public static toTitleCase(str: string): string {
        return this.otherToCamel(str, " ");
    }

    public static camelToSnake(str: string): string {
        return S25Util.camelToOther(str, "_");
    }

    public static snakeToCamel(str: string): string {
        return S25Util.otherToCamel(str, "_");
    }

    public static camelToSnakeObj(obj: any): any {
        let ret: any = {};
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let snakeKey = S25Util.camelToSnake(key);
                let value = obj[key];
                if (S25Util.isObject(value) && !S25Util.date.isDate(value)) {
                    ret[snakeKey] = S25Util.camelToSnakeObj(value);
                } else {
                    ret[snakeKey] = value;
                }
            }
        }
        return ret;
    }

    /**begin duration**/
    public static ISODurationToMinutes(extraStr: string): number {
        if (!extraStr) {
            return 0;
        }

        let minutes = 0;
        extraStr = extraStr.trim();
        extraStr = extraStr.toUpperCase();

        let p = extraStr.indexOf("P");
        let d = extraStr.indexOf("D");
        let t = extraStr.indexOf("T");
        let h = extraStr.indexOf("H");
        let m = extraStr.indexOf("M");

        if (d > -1) {
            minutes += parseInt(extraStr.substring(p + 1, d)) * 24 * 60;
        }

        if (t > -1) {
            if (h > -1) {
                minutes += parseInt(extraStr.substring(t + 1, h)) * 60;
                t = h;
            }

            if (m > -1) {
                minutes += parseInt(extraStr.substring(t + 1, m));
            }
        }

        return minutes;
    }

    public static minutesToISODuration(minutes?: number): string {
        return S25Util.daysHoursMinutesToDuration(S25Util.daysHoursMinutesObj(minutes));
    }

    public static daysHoursMinutesObj(minutes?: number): DurationI {
        minutes = minutes || 0;
        let ret = { days: 0, hours: 0, minutes: 0 };

        ret.days = Math.floor(minutes / 24 / 60);
        minutes -= ret.days * 24 * 60;

        ret.hours = Math.floor(minutes / 60);
        ret.minutes = minutes - ret.hours * 60;

        return ret;
    }

    public static ISODurationToObj(isoDuration: string) {
        return S25Util.daysHoursMinutesObj(S25Util.ISODurationToMinutes(isoDuration));
    }

    public static ISODurationToStr(isoDuration: string) {
        return S25Util.daysHoursMinutesToStr(S25Util.ISODurationToObj(isoDuration));
    }

    public static daysHoursMinutesToStr(daysHoursMinutes: DurationI): string {
        return [
            daysHoursMinutes.days ? daysHoursMinutes.days + " Days" : null,
            daysHoursMinutes.hours ? daysHoursMinutes.hours + " Hrs" : null,
            daysHoursMinutes.minutes ? daysHoursMinutes.minutes + " Mins" : null,
        ]
            .filter(S25Util.isDefined)
            .join(", ");
    }

    public static daysHoursMinutesToDuration(dhm: DurationI): string {
        if (dhm.days + dhm.hours + dhm.minutes === 0) {
            return "P0DT00H00M";
        }
        return (
            "P" +
            (dhm.days ? dhm.days + "D" : "") +
            (dhm.hours + dhm.minutes === 0
                ? ""
                : "T" +
                  (dhm.hours ? S25Util.leftPad(dhm.hours, 2) + "H" : "") +
                  (dhm.minutes ? S25Util.leftPad(dhm.minutes, 2) + "M" : ""))
        );
    }

    public static getDurationString(minuteDur: number): string {
        let parts = [];
        minuteDur = S25Util.parseInt(minuteDur);

        let days = S25Util.parseInt(minuteDur / (24 * 60));
        days > 0 && parts.push(days + " Day" + (days > 1 ? "s" : ""));
        minuteDur -= days * 24 * 60;

        let hours = S25Util.parseInt(minuteDur / 60);
        hours > 0 && parts.push(hours + " Hour" + (hours > 1 ? "s" : ""));
        minuteDur -= hours * 60;

        minuteDur > 0 && parts.push(minuteDur + " Minute" + (minuteDur > 1 ? "s" : ""));

        return parts.join(", ");
    }

    // Returns duration in milliseconds
    // E.G. -P5Y2M10DT15H36M5S -> -163784165000
    public static parseXmlDuration(expression: string): number {
        if (!expression) return 0;
        const regex = /^(-)?P((\d+)Y)?((\d+)M)?((\d+)D)?(T((\d+)H)?((\d+)M)?((\d+)S)?)?$/g;
        const [_0, neg, _1, year, _2, month, _3, day, _4, _5, hour, _6, min, _7, sec] = regex.exec(expression);
        let duration = 0;
        if (year) duration += S25Const.ms.year * parseInt(year);
        if (month) duration += S25Const.ms.month * parseInt(month);
        if (day) duration += S25Const.ms.day * parseInt(day);
        if (hour) duration += S25Const.ms.hour * parseInt(hour);
        if (min) duration += S25Const.ms.min * parseInt(min);
        if (sec) duration += S25Const.ms.sec * parseInt(sec);
        return neg ? -duration : duration;
    }

    //get duration by the start and end numbers, ie: start = 540, end = 620
    public static timesDuration(start: any, end: any) {
        start = S25Util.timeConvert(parseInt(start));
        end = S25Util.timeConvert(parseInt(end));
        const startSplit = start.split(":");
        const endSplit = end.split(":");
        return (endSplit[0] - startSplit[0]) * 60 + (endSplit[1] - startSplit[1]);
    }

    /**end duration**/
    //convert an number to hours and mintues. ie: start = 540 = 09:00
    public static timeConvert(num: number) {
        const hours = Math.floor(num / 60);
        const minutes = num % 60;
        return S25Util.leftPad(hours, 2) + ":" + S25Util.leftPad(minutes, 2);
    }

    public static setOnObjectDeep(obj: any, prop: string, value: any, cache?: any, override?: boolean): any {
        cache = cache || [];
        if (S25Util.isObject(obj) && !S25Util.date.isDate(obj) && !S25Util.refEq(obj, cache)) {
            cache.push(obj);
            jSith.forEach(obj, (_: any, childObj: any) => {
                S25Util.setOnObjectDeep(childObj, prop, value, cache);
            });

            obj[prop] = override ? value : S25Util.coalesce(obj[prop], value);
        }
    }

    //http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    public static componentToHex(c: number): string {
        let hex: string = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    public static isIEBrowser: boolean = window.navigator.userAgent.match(/Trident\/|Edge\/|MSIE /) != null;

    public static isIENotEdge: boolean = window.navigator.userAgent.match(/Trident\/|MSIE /) != null;

    public static isPositionStickySupported: boolean = (function () {
        //used in s25-scroll-header
        var el = document.createElement("a"),
            mStyle = el.style;
        mStyle.cssText =
            "position:sticky;position:-webkit-sticky;position:-ms-sticky;position:-moz-sticky;position:-o-sticky;";
        return mStyle.position.indexOf("sticky") !== -1;
    })();

    public static isInIframe: boolean =
        (window.ProData && window.ProData.isInIframe) ||
        (function () {
            //eg, isEmbedded
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        })();

    public static setDarkMode(isDark: boolean): void {
        if (isDark) {
            jSith.addClass("#s25", "nm-party--on");
            jSith.addClass("#themeTag", "nm-party--on");
        } else {
            jSith.removeClass("#s25", "nm-party--on");
            jSith.removeClass("#themeTag", "nm-party--on");
        }
    }

    public static setInIFrameClass(): void {
        if (S25Util.isInIframe) {
            document.getElementById("s25").classList.add("inside-iframe");
        }
    }

    public static parseFloat(str: any): number {
        str = S25Util.toStr(str);
        return typeof Number.parseFloat === "function" ? Number.parseFloat(str) : parseFloat(str);
    }

    public static isFloat(n: string | number): boolean {
        return !isNaN(S25Util.parseFloat(n));
    }

    public static toFloat(n: string | number): number {
        return S25Util.parseFloat(n) || 0;
    }

    public static mobileCheck(): boolean {
        if (typeof S25Util.isMobile !== "undefined") {
            return S25Util.isMobile;
        }

        //https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
        // check if browser is mobile
        let check: boolean = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a,
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        S25Util.isMobile = check;
        return S25Util.isMobile;
    }

    public static postMessage(data: any): any {
        if (window.parent === window) return; // Don't send messages to yourself
        return window.parent?.postMessage(S25Util.stringify(data), "*");
    }

    public static parseXml(str: string): any {
        str = str.replace(/\<([A-Za-z_]+)\s*([A-Za-z_\s":=])*\/>/gi, "<$1></$1>");
        return new window.DOMParser().parseFromString(str, "text/html").body.innerHTML;
    }

    public static parseSpaceResourcePreferences(prefStr: string): any {
        if (prefStr) {
            //expand self-closing tags as they cause the DOMParser to subsume the next element for some reason...
            let prefXml: string = S25Util.parseXml(prefStr);
            //ANG-3625 &nbsp in the event text was preventing it from being parsed.
            prefXml = prefXml.replace(/&nbsp;/gi, "&#xA0;");
            if (prefXml) {
                return S25Util.prettyConv(prefXml, null, {
                    reservation: true,
                    space_reservation: true,
                    resource_reservation: true,
                });
            }
            return {};
        }
        return {};
    }

    public static nestedStr(val: any): string {
        return (S25Util.coalesce(val, "") + "").replace(/'/g, "\\'").replace(/"/g, '\\"');
    }

    public static waitForElement($elem: any, selector: string, ms?: number, maxTries?: number, tries?: number): any {
        ms = ms || 250;
        tries = tries || 0;

        if (maxTries && tries > maxTries) {
            // return jSith.reject(new Error("Element not found"));
            return window.angBridge.$q.reject(new Error("Element not found"));
        }

        let results: any[] = [];
        if (!$elem) {
            results = jSith.element(selector);
        } else {
            results = jSith.find($elem, selector);
        }

        if (results && results.length) {
            // return jSith.resolve(results);
            return window.angBridge.$q.when(results);
        } else {
            // return jSith.timeout(function() {
            return window.angBridge.$timeout(function () {
                tries++;
                return S25Util.waitForElement($elem, selector, ms, maxTries, tries);
            }, ms);
        }
    }

    public static add(num: number) {
        return function (x: number): number {
            return x + num;
        };
    }

    //add query param, add url param, inject url parameter, inject property, inject url property or attribute
    public static addQueryParam(param: string, searchQuery: string): string {
        if (!param) {
            return searchQuery;
        }

        let searchQueryParts: Array<string> = searchQuery.split("&").filter(S25Util.toBool);
        let paramParts: Array<string> = param.split("&").filter(S25Util.toBool);

        jSith.forEach(paramParts, function (_: any, part: string) {
            let paramParam: string = part.split("=")[0];
            let matches: Array<string> = searchQueryParts.filter(function (p: string) {
                return p.indexOf(paramParam + "=") > -1 || p === paramParam;
            });
            if (matches.length) {
                searchQueryParts.splice(searchQueryParts.indexOf(matches[0]), 1, part);
            } else {
                searchQueryParts.push(part);
            }
        });

        return "&" + searchQueryParts.join("&");
    }

    public static addParam(url: string, name: string, value?: string | number) {
        url += value ? (url.indexOf("?") > -1 ? "&" + name : "?" + name) + "=" + value : "";
        return url;
    }

    public static toBool(bool: any): boolean {
        //convert falsy, even "false", "0", to false, truthy to true
        return !(
            bool == "F" ||
            bool === "false" ||
            bool === "0" ||
            bool === 0 ||
            bool === false ||
            bool === "" ||
            bool === undefined ||
            bool === null
        );
    }

    public static toInt(n: string | number): number {
        return S25Util.parseInt(n, 10) || 0;
    }

    public static isInt = jSith.isInt;

    public static parseInt = jSith.parseInt;

    public static toStr = jSith.toStr;

    public static isUndefined = jSith.isUndefined;

    public static isDefined = jSith.isDefined;

    public static coalesce = jSith.coalesce;

    public static xml: any = {
        // https://code.google.com/p/x2js/
        json2xml_str: (json: any): any => {
            return S25Util.GET_X2JS_INSTANCE().json2xml_str(json);
        },

        xml_str2json: (xml: any): any => {
            return S25Util.GET_X2JS_INSTANCE().xml_str2json(xml);
        },

        find: (element: any, name: string): any => {
            // because of namespaces we use iterative match:
            // http://stackoverflow.com/questions/853740/jquery-xml-parsing-with-namespaces
            return jSith.find(element, "*").filter(function () {
                return this.nodeName === name;
            });
        },
    };

    public static escapeRegExp(str: string) {
        return (str || "").replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"); //$& means whole matched string
    }

    public static replaceAll(str: string, find: string, replace: string) {
        return str.replace(new RegExp(S25Util.escapeRegExp(find), "g"), replace);
    }

    public static unescapeXml(str: string | number): string {
        str = S25Util.toStr(str);
        return (
            (str &&
                str
                    .replace(/&amp;/g, "&")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&apos;/g, "'")) ||
            str
        );
    }

    //https://stackoverflow.com/questions/7918868/how-to-escape-xml-entities-in-javascript
    public static escapeXml(str: string | number, doubleEscape?: boolean, skipList?: string[]): string {
        skipList = skipList || [];
        let resp: string = S25Util.toStr(str);
        let replaceList: any[] = [
            { find: "&", replace: "&amp;" },
            { find: "&amp;nbsp;", replace: "&#xA0;" },
            { find: "nbsp;", replace: "&#xA0;" },
            { find: "<", replace: "&lt;" },
            { find: ">", replace: "&gt;" },
            { find: '"', replace: "&quot;" },
            { find: "'", replace: "&apos;" },
        ];

        jSith.forEach(skipList, (_: any, s: string) => {
            let idx = S25Util.array.findByProp(replaceList, "find", s);
            if (idx > -1) {
                replaceList.splice(idx, 1);
            }
        });

        jSith.forEach(replaceList, (_: any, r: any) => {
            resp = resp.replace(new RegExp(r.find, "ig"), r.replace);
        });

        return doubleEscape ? S25Util.escapeXml(resp, false, skipList) : resp;
    }

    public static removeInvalidXmlCharacters(input: any) {
        if (S25Util.isObject(input)) {
            S25Util.dfs(input, (node: any, parentNode: any, childKey: any) => {
                if (typeof node === "string") {
                    parentNode[childKey] = S25Util.removeInvalidXmlCharacters(node);
                }
            });
        } else if (typeof input === "string") {
            // remove everything forbidden by XML 1.0 specifications, plus the unicode replacement character U+FFFD
            return input
                .replace(
                    /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g,
                    "",
                )
                .replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F-\x9F]/g, "")
                .replace(/[\u0000-\u0008\u000E-\u001F\u007F-\u009F]/g, "");
        }
    }

    public static unicodeToChar(text: string, escape?: boolean): string {
        return text.replace(/\\u[A-F0-9]{1,4};/gi, function (match: string) {
            let resp: string = String.fromCharCode(parseInt(match.replace(/\\u|;/g, ""), 16));
            return escape ? S25Util.escapeXml(resp) : resp;
        });
    }

    public static hexEntityToChar(text: string, escape?: boolean): string {
        return text.replace(/&#x[A-F0-9]{1,4};/gi, function (match: string) {
            let resp: string = String.fromCharCode(parseInt(match.replace(/[&#x;]/g, ""), 16));
            return escape ? S25Util.escapeXml(resp) : resp;
        });
    }

    public static numericEntityToChar(text: string, escape?: boolean): string {
        return text.replace(/&#\d{1,4};/gi, function (match: string) {
            let resp: string = String.fromCharCode(parseInt(match.replace(/[&#;]/g, "")));
            return escape ? S25Util.escapeXml(resp) : resp;
        });
    }

    public static json2Xml(json: JSON): string {
        //json2xml_str hex/numeric/unicode encodes many characters, which is not necessary for xml as they are totally valid
        //in xml. We undo that encoding with the *ToChar methods because Java xml->json does NOT render the characters, but keeps
        //the encoding as plain-text
        return S25Util.unicodeToChar(
            S25Util.hexEntityToChar(S25Util.numericEntityToChar(S25Util.xml.json2xml_str(json), true), true),
            true,
        );
    }

    public static getWindowOrigin(): string {
        //mockable function call to get origin
        return window.location.origin;
    }

    public static getWindowPath(): string {
        // mockable function call to get pathname
        // remove embedded portion of the url if there is one, note that pathname does not end in a slash
        return window.location.pathname.replace(/\/embedded\/.*/, "");
    }

    public static getURL(postHostPath: string, postInstancePath: string): string {
        postHostPath = postHostPath || "/";
        postInstancePath = postInstancePath || "";
        let host: string = window.location.hostname;
        if (host === "localhost") {
            host = "25live.collegenet.com";
        }

        if (S25Const.isHostUriSeries25 && postHostPath && postHostPath !== "/") {
            postInstancePath = postHostPath;
            if (postInstancePath && postInstancePath.lastIndexOf("/") === postInstancePath.length - 1) {
                postInstancePath = postInstancePath.substr(0, postInstancePath.length - 1);
            }
            postHostPath = "/";
        }

        return window.location.protocol + "//" + host + postHostPath + S25Const.instanceId + postInstancePath;
    }

    public static getEventDetailsUrl(eventId: string | number, app?: string): string {
        //examples:
        //https://25live.collegenet.com/pro/wes#!/home/event/12441/details
        //https://25live.collegenet.com/wes/#details&obj_type=event&obj_id=24717
        //https://25live.collegenet.com/burnside/scheduling.html#/events/details/137977

        //note: we cannot use s25Const.baseUrl bc developer boxes are too different and also series25.com switches pro/instance to instance/pro
        //whereas origin + pathname is guaranteed to give us the proper base url from which we can tack on the routing hash in ALL environments
        let url: string = S25Util.getWindowOrigin() + S25Util.getWindowPath();
        app = app || "pro";
        if (app === "pro") {
            url += "#!/home/event/" + eventId + "/details";
        } else if (app === "25live") {
            url = url.replace("/pro/", "/"); //remove pro from url if present
            url = url.replace(/\/pro$/, ""); //remove pro from url if present
            url += "#details&obj_type=event&obj_id=" + eventId;
        } else if (app === "scheduling") {
            url = url.replace("/pro/", "/"); //remove pro from url if present
            url = url.replace(/\/pro$/, ""); //remove pro from url if present
            url = S25Util.addSlash(url) + "scheduling.html";
            url += "#/events/details/" + eventId;
        }
        return url;
    }

    public static toSeries25DataUrl(url: string) {
        if (S25Const.isHostUriSeries25 && url?.indexOf("25live.collegenet.com") > -1) {
            url = url.replace("https://25live.collegenet.com", S25Util.getWindowOrigin);
            url = url.replace("http://25live.collegenet.com", S25Util.getWindowOrigin);
            url = url.replace("/25live/data/", "/");
            url = url.replace("/run/", "/data/");
        }
        return url;
    }

    public static addSlash(str: string): string {
        if (str.substring(str.length - 1) !== "/") {
            str += "/";
        }
        return str;
    }

    public static lastDigit(num: string | number): number {
        num = Math.abs(S25Util.parseInt(num));
        let numDigits: number = Math.floor(Math.log10(num)) + 1;
        for (let i: number = numDigits - 1; i >= 1; i--) {
            let ithPower: number = Math.pow(10, i);
            num -= Math.floor(num / ithPower) * ithPower;
        }
        return num;
    }

    public static throttle(fn: Function, ms?: number, accumulateArgNum?: number) {
        return S25Util.debounce(fn, ms, false, accumulateArgNum, true, true);
    }

    // Typesafe version of debounce
    // Also does not use $q
    public static debounced<F extends (...params: any[]) => ReturnType<F>>(
        func: F,
        ms: number,
        immediate: boolean = false, // Trigger right away if it's been more than "ms" ms since last call
    ) {
        let timeout: ReturnType<typeof setTimeout> = null;
        let promise: Promise<ReturnType<F>>;
        let resolve: (value: ReturnType<F>) => void;
        let reject: (value: any) => void;
        let lastCall: number = 0;
        return function (...args: Parameters<F>): Promise<ReturnType<F>> {
            if (!promise) {
                promise = new Promise((res, rej) => {
                    resolve = res;
                    reject = rej;
                });
            }
            const elapsed = Date.now() - lastCall;
            lastCall = Date.now();
            if (immediate && elapsed > ms) {
                return Promise.resolve(func.apply(this, args));
            }
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                resolve(func.apply(this, args));
                promise = null;
            }, ms);
            return promise;
        };
    }

    // Typesafe version of throttle
    // Also does not use $q
    public static throttled<F extends (...params: any[]) => ReturnType<F>>(
        func: F,
        ms: number,
        debounceLast: boolean = true,
    ) {
        let timeout: ReturnType<typeof setTimeout> = null;
        let promise: Promise<ReturnType<F>>;
        let resolve: (value: ReturnType<F>) => void;
        let reject: (value: any) => void;
        let lastCall: number = 0;
        return function (...args: Parameters<F>): Promise<ReturnType<F>> {
            if (!promise) {
                promise = new Promise((res, rej) => {
                    resolve = res;
                    reject = rej;
                });
            }
            clearTimeout(timeout);
            const elapsed = Date.now() - lastCall;
            if (ms - elapsed <= 0) {
                lastCall = Date.now();
                return Promise.resolve(func.apply(this, args));
            }
            if (!debounceLast) return promise; // Drop call if it's not time
            timeout = setTimeout(() => {
                lastCall = Date.now();
                resolve(func.apply(this, args));
                promise = null;
            }, ms - elapsed);
            return promise;
        };
    }

    //only run 1 fn call every ms milliseconds no matter how many come in -- if another comes in too fast, we clear the last one and start over with the new one, and so on
    //returns: function that returns promise
    public static debounceGenerator(
        deferFunc: Function,
        timeoutFunc: Function,
        clearFunc: Function,
        fn: Function,
        ms?: number,
        rejectOnClear?: boolean,
        accumulateArgNum?: number,
        resolveOnFinal?: boolean,
        throttle?: boolean,
    ) {
        ms = ms || 250;

        let timeout: any,
            defer = deferFunc(),
            accumulator: any[] = [],
            counter = 0;

        //return wrapper function that wraps debounce functionality around fn and can be called the exact same way as the original fn
        return function (...argz: any): any {
            //note: arguments variable is implied is js so no need to actually bind variables here...
            let args = argz; //keep reference to variables passed in (even though none are formally bound above) so we can apply them to fn after ms milliseconds...

            //push arguments for each call into accumulator, if accumulateArgNum provided
            if (accumulateArgNum > -1 && args[accumulateArgNum] && args[accumulateArgNum].length) {
                Array.prototype.push.apply(accumulator, args[accumulateArgNum]);
            }

            if (timeout) {
                //implies defer is also set
                //clear timeout so it never executes (ie, last call never executes)
                //we clear bc it means another request has come in *before* the last one has waited ms milliseconds
                //note: if throttling (NOT debouncing): we execute once every X ms like an interval, so don't cancel; instead, return
                !throttle && clearFunc(timeout);

                if (!resolveOnFinal) {
                    if (rejectOnClear) {
                        defer.reject("debounce timeout cleared"); //reject any promise attached to last call
                    } else {
                        defer.resolve("debounce timeout cleared"); //silently resolve it but with a message indicating the clear (angular logs uncaught rejection if reject called w/ no handler...)
                    }
                }

                if (throttle) {
                    return defer.promise;
                }
            }

            if (!resolveOnFinal) {
                //set defer so we can return a promise
                defer = deferFunc();
            }

            //local ref to defer so any other callers do not change it for this call
            let myDefer = defer;

            counter++;
            let closureCounter = counter;

            //set timeout for execution -- it will be cleared (and promise rejected) if another call happens before timeout period (ms)
            timeout = timeoutFunc(function () {
                if (accumulateArgNum > -1) {
                    accumulator = S25Util.array.uniqueDeep(accumulator); //unique accumulated param
                    args[accumulateArgNum] = Array.prototype.splice.apply(accumulator, [0]); //set param to all unique entries
                }
                let resp: any = fn.apply(null, args); //no calls w/ in timeout period, so run function with arguments
                timeout = null; //nullify timeout
                defer = deferFunc();
                if (resp && resp.then) {
                    //if function returns promise...
                    resp.then(function (data: any) {
                        //then return the resolved defer with the function's data response
                        if (resolveOnFinal) {
                            //resolve all calls at once, obvs order does not matter; reset defer for another round
                            myDefer.resolve(data);
                        } else if (counter === closureCounter) {
                            //todo: only care if params are the same and caller cares about order (specific occ avail checking...)
                            myDefer.resolve(data);
                        }
                    });
                } else {
                    myDefer.resolve(resp); //resolve with literal response
                    if (resolveOnFinal) {
                        defer = deferFunc();
                    }
                }
            }, ms);
            return myDefer.promise; //return deferred promise
        };
    }

    public static debounce(
        fn: Function,
        ms?: number,
        rejectOnClear?: boolean,
        accumulateArgNum?: number,
        resolveOnFinal?: boolean,
        throttle?: boolean,
    ) {
        return S25Util.debounceGenerator(
            window.angBridge?.$q?.defer,
            window.angBridge?.$timeout,
            window.angBridge?.$timeout?.cancel,
            fn,
            ms,
            rejectOnClear,
            accumulateArgNum,
            resolveOnFinal,
            throttle,
        );
    }

    public static debounceTs(
        fn: Function,
        ms?: number,
        rejectOnClear?: boolean,
        accumulateArgNum?: number,
        resolveOnFinal?: boolean,
        throttle?: boolean,
    ) {
        return S25Util.debounceGenerator(
            jSith.defer,
            setTimeout,
            clearTimeout,
            fn,
            ms,
            rejectOnClear,
            accumulateArgNum,
            resolveOnFinal,
            throttle,
        );
    }

    public static refEq(comp: any, cache: any[]): any {
        if (cache && cache.length) {
            for (let i = cache.length - 1; i >= 0; i--) {
                if (cache[i] === comp) {
                    return { i: i };
                }
            }
            return null;
        } else {
            return null;
        }
    }

    public static deepCopy<T, Y extends any = never>(
        json: T,
        skipMap?: any,
        cache?: Map<any, any>,
    ): ValueElseUndefined<Y> extends undefined ? T : DeepOmit<T, keyof Y> {
        let skipObj = (key: string, val: any) => {
            if (skipMap && key in skipMap) return true;
            if (key.indexOf("$") === 0) return true;

            if (!val) return false;
            if (val.constructor === Window) return true;
            if (val._zoneDelegate || val._zone || val.__ngContext__) return true;
            if (val instanceof HTMLElement) return true;
            if (val.constructor === Window) return true;

            if (!window.angBridge?.$rootScope) return false;
            if (val.constructor === window.angBridge.$rootScope.constructor) return true;

            return false;
        };

        if (skipObj("", json)) {
            return null;
        }

        let ret: any = null;
        let isArray: boolean = S25Util.array.isArray(json);

        if (isArray) {
            ret = [];
        } else if (S25Util.date.isDate(json)) {
            return S25Util.date.clone(json) as any;
        } else if (S25Util.isObject(json)) {
            ret = {};
        } else {
            return json as unknown as any;
        }

        cache = cache || new Map<any, any>();

        if (json) {
            for (let key in json) {
                if (json.hasOwnProperty && json.hasOwnProperty(key)) {
                    if (isArray && !S25Util.isInt(key)) {
                        continue;
                    }

                    let val = json[key];

                    if (skipObj(key, val)) {
                        continue;
                    }

                    if (cache.has(val)) {
                        ret[key] = cache.get(val);
                    } else if (S25Util.date.isDate(val)) {
                        ret[key] = S25Util.date.clone(val);
                    } else if (S25Util.isFunction(val)) {
                        ret[key] = val;
                    } else if (S25Util.array.isArray(val)) {
                        //loop array
                        const arr = val as unknown as any[];
                        ret[key] = [];
                        for (let i = 0; i < arr.length; i++) {
                            if (skipObj(key, arr[i])) {
                                continue;
                            }
                            ret[key].push(S25Util.deepCopy(arr[i], skipMap, cache));
                        }
                    } else if (S25Util.isObject(val)) {
                        //recur obj
                        ret[key] = S25Util.deepCopy(val, skipMap, cache);
                    } else if (["string", "number", "boolean", "undefined", "null"].indexOf(typeof val) > -1) {
                        //handle key/val
                        ret[key] = val;
                    }
                    cache.set(val, ret[key]);
                }
            }
            return ret;
        } else {
            return json as unknown as any;
        }
    }

    public static toBase64URI(base64: string, type: string): string {
        return "data:image/" + type + ";base64," + base64;
    }

    public static extractImageTypeFromURI(uri: string): string {
        return uri.substring(uri.indexOf("image/") + 6, uri.indexOf(";base64,"));
    }

    public static extractBase64FromURI(uri: string): string {
        return uri.substring(uri.indexOf("base64,") + 7);
    }

    //dynamic programming implementation of min edit dist (substit is same cost as insert/delete)
    public static minEditDist(s1: string, s2: string): number {
        s1 = s1 || "";
        s2 = s2 || "";
        let tbl: number[][] = [[]]; //2d array of form [s1.len + 1][s2.len + 1] = 0
        let s2Arr = []; //helper array to get [s2.len + 1] = 0
        let i = 0;
        for (i = 0; i <= s2.length; i++) {
            s2Arr.push(0);
        }
        for (i = 0; i <= s1.length; i++) {
            //initiate tbl to [s1.len + 1][s2.len + 1] = 0
            tbl.push([].concat(s2Arr));
        }

        for (i = 0; i <= s1.length; i++) {
            for (let j = 0; j <= s2.length; j++) {
                if (i === 0) {
                    tbl[i][j] = j; //if i is 0, then we must insert the rest of j (s2) so the cost is j to go from 0/empty string to j
                } else if (j === 0) {
                    //if j is 0, similarly, to go from 0/empty string to i is i
                    tbl[i][j] = i;
                } else if (s1[i - 1] === s2[j - 1]) {
                    tbl[i][j] = tbl[i - 1][j - 1];
                } else {
                    tbl[i][j] =
                        1 +
                        Math.min(
                            //each op has cost of 1 and we want the min of remaining choices
                            tbl[i - 1][j], //deletion of char in s1 to get closer to s2
                            tbl[i][j - 1], //insertion of char into s1 to get closer to s2
                            tbl[i - 1][j - 1], //replacement of char in s1 with char in s2 to get closer to s2
                        );
                }
            }
        }
        return tbl[s1.length][s2.length];
    }

    //adapted from https://www.w3schools.com/js/js_cookies.asp
    public static setCookie(
        cname: string,
        cvalue: string | number | boolean,
        exminutes?: number,
        secure?: boolean,
        path?: string,
    ): void {
        exminutes = exminutes || 60 * 24; //default to 1 day
        path = path || "/";
        var d = new Date();
        d.setTime(d.getTime() + exminutes * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path + (secure ? ";secure" : "");
    }

    //adapted from https://www.w3schools.com/js/js_cookies.asp
    public static getCookie(cname: string): string {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            c = c.substring(c.indexOf(" ") + 1); //skip spaces
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    public static deleteCookie(cname: string, path?: string) {
        S25Util.setCookie(cname, "", -10000, null, path);
    }

    public static deleteFalseProperties(item: any): void {
        for (let prop in item) {
            if (item.hasOwnProperty(prop)) {
                item[prop] === false && delete item[prop];
            }
        }
    }

    public static completeEventLocator(str: string): string {
        if (str) {
            if (S25Util.isEventLocator(str)) {
                return str;
            } else if (S25Util.isPartialEventLocator(str)) {
                return new Date().getFullYear() + "-" + str;
            } else {
                return null;
            }
        }
    }

    public static isPartialEventLocator(str: string): boolean {
        return str && !!str.match(S25Const.partialLocatorRegex);
    }

    public static isEventLocator(str: string): boolean {
        return !!str && !!str.match(S25Const.eventLocatorRegex);
    }

    public static coalescePromise(maybePromise: any, then: Function) {
        if (maybePromise) {
            if (maybePromise.then) {
                return maybePromise.then(then);
            } else {
                // return Promise.resolve(then(maybePromise));
                return window.angBridge.$q.when(then(maybePromise));
            }
        } else {
            // return Promise.resolve();
            return window.angBridge.$q.when();
        }
    }

    public static extractAddress(addrJson: any, addrType: number) {
        //addrType: 1 for "admin", 2 for "billing", 3 for "work", 4 for "home"
        let addr: any = S25Util.propertyGetParentWithChildValue(addrJson, "address_type", addrType);
        let lines: string[] = addr?.street_address?.toString().split(", ");
        let line1: string = lines && lines[0];
        let line2: string = lines && lines.length > 1 && lines.splice(1).join(", ");
        return {
            line1: line1 || "",
            line2: line2 || "",
            city: addr?.city || "",
            state: addr?.state_prov || "",
            zip: addr?.zip_post || "",
            country: addr?.country || "",
            phone: addr?.phone || "",
            fax: addr?.fax || "",
        };
    }

    public static addressObjToNode(addrObj: any, addrType: number) {
        let addrNode: any = {
            street_address: addrObj.street || "",
            country: addrObj.country || "",
            address_type: addrType,
            city: addrObj.city || "",
            zip_post: addrObj.zip || "",
            phone: addrObj.phone || "",
            state_prov: addrObj.state || "",
            fax: addrObj.fax || "",
        };

        if ([3, 4].indexOf(addrType) > -1) {
            addrNode.email = addrObj.email || "";
        }

        return addrNode;
    }

    public static leftPad(val: any, desiredLen: number, padWith?: any): string {
        val = S25Util.toStr(val);
        padWith = padWith || "0";
        return val.length >= desiredLen ? val : new Array(desiredLen - val.length + 1).join(padWith) + val;
    }

    public static stableSort(arr: any[], cmp: (a: any, b: any) => number) {
        let positions: any[] = [];
        jSith.forEach(arr, function (i: number, e: any) {
            positions.push({
                position: i,
                element: e,
            });
        });

        return positions.sort(function (a: any, b: any) {
            let cmpResult = cmp(a.element, b.element);
            if (cmpResult === 0) {
                return a.position - b.position;
            }
            return cmpResult;
        });
    }

    public static sortMultiProps(arr: any[], properties?: string[], types?: string[], desc?: boolean[]) {
        if (properties) {
            properties.reverse();
            jSith.forEach(properties, function (i: number, p: string) {
                let type: string = null;
                if (types && types.length > i) {
                    type = types[i];
                }

                let isDesc: boolean = false;
                if (desc && desc.length > i) {
                    isDesc = desc[i];
                }

                S25Util.stableSort(arr, function (a: any, b: any) {
                    let aVal: any = a[p];
                    let bVal: any = b[p];

                    if (type === "number") {
                        aVal = Number(aVal);
                        bVal = Number(bVal);
                    } else if (type === "date") {
                        aVal = S25Util.date.toS25ISODateTimeStr(aVal);
                        bVal = S25Util.date.toS25ISODateTimeStr(bVal);
                    }

                    if (aVal < bVal) {
                        return isDesc ? 1 : -1;
                    } else if (aVal > bVal) {
                        return isDesc ? -1 : 1;
                    } else {
                        return 0;
                    }
                });
            });
        }
    }

    public static shallowSort(property: string, asNumber?: boolean, isDesc?: boolean, transformF?: Function) {
        return function (a: any, b: any) {
            let aVal: any = ("" + ((transformF && transformF(a[property])) || a[property])).toLowerCase();
            let bVal: any = ("" + ((transformF && transformF(b[property])) || b[property])).toLowerCase();
            if (asNumber) {
                aVal = Number(aVal);
                bVal = Number(bVal);
            }
            if (aVal < bVal) {
                return isDesc ? 1 : -1;
            } else if (aVal > bVal) {
                return isDesc ? -1 : 1;
            } else {
                return 0;
            }
        };
    }

    public static sortNumeric() {
        return function (a: any, b: any) {
            if (Number(a) < Number(b)) {
                return -1;
            } else if (Number(a) > Number(b)) {
                return 1;
            } else {
                return 0;
            }
        };
    }

    public static shallowSortMultProps(
        properties: string[],
        delimiter?: string,
        defaultVal?: any,
        isDesc?: boolean,
        transformF?: Function,
    ) {
        delimiter = S25Util.coalesce(delimiter, "$$$"); //safe-ish delimiter
        defaultVal = S25Util.coalesce(defaultVal, "~"); //default to last position
        return function (a: any, b: any) {
            var aVal = "",
                bVal = "";
            jSith.forEach(properties, function (key: any, p: any) {
                aVal += "" + S25Util.coalesce((transformF && transformF(a[p])) || a[p], defaultVal) + delimiter;
                bVal += "" + S25Util.coalesce((transformF && transformF(b[p])) || b[p], defaultVal) + delimiter;
            });

            aVal = aVal.toLowerCase();
            bVal = bVal.toLowerCase();

            if (aVal < bVal) {
                return isDesc ? 1 : -1;
            } else if (aVal > bVal) {
                return isDesc ? -1 : 1;
            } else {
                return 0;
            }
        };
    }

    public static shallowSortDates(startDtProp: string, endDtProp: string) {
        let props: string[] = [startDtProp];
        endDtProp && props.push(endDtProp);
        return S25Util.shallowSortMultProps(props, null, null, null, S25Util.date.toS25ISODateTimeStr);
    }

    public static sortAlphaNum() {
        return function (a: any, b: any) {
            if (Number(a) && !Number(b)) {
                return -1;
            } else if (!Number(a) && Number(b)) {
                return 1;
            } else if (Number(a) && Number(b)) {
                return a - b;
            } else if (!Number(a) && !Number(b)) {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                } else if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            }
        };
    }

    public static errorTextAll(s25Resp: any, defaultText: string): string[] {
        let msg = "",
            json: any = null;
        let messages: any[] = [];

        if (!s25Resp) {
            msg = defaultText;
        } else if (typeof s25Resp === "string" && s25Resp.indexOf("</") === -1) {
            //string
            msg = s25Resp;
        } else if (
            typeof s25Resp === "object" &&
            (!s25Resp.data || !s25Resp.data.indexOf || s25Resp.data.indexOf("</") === -1)
        ) {
            //json
            json = s25Resp;
        } else {
            //xml
            json = s25Resp && s25Resp.data && S25Util.prettyConv(s25Resp.data);
        }

        if (json) {
            let errorData = json;
            if (errorData.data) {
                errorData = errorData.data;
            }

            if (!msg) {
                msg =
                    S25Util.propertyGet(errorData, "msg") ||
                    S25Util.propertyGet(errorData, "error_msg") ||
                    S25Util.propertyGet(errorData, "err_msg") ||
                    S25Util.propertyGet(errorData, "message");
            }

            //json.message may be special try/catch error property skipped by propertyGet due to Error not having a toJSON property for serialization
            if (!msg) {
                msg = json.message || defaultText;
            }

            //We've collected all the messsages. Each message may have details lets add them... somehow.

            let details = S25Util.propertyGetAllUnique(errorData, "proc_error");
            details = details.length < 1 ? S25Util.propertyGetAllUnique(errorData, "procError") : details;
            details = details.length < 1 ? S25Util.propertyGetAllUnique(errorData, "error_detail") : details;

            if (msg && details) {
                msg = msg.trim();
                details = S25Util.array.forceArray(details);
                for (let detail of details) {
                    detail = S25Util.isString(detail) ? detail.trim() : detail.content && detail.content.trim();
                    let objectName = S25Util.propertyGet(errorData, "object_name");
                    if (objectName) {
                        detail +=
                            ([".", "?", "!", ";", ":"].indexOf(detail.substring(details.length - 1)) > -1 ? "" : ".") +
                            " Object: " +
                            objectName;
                    }

                    //add punctuation to msg, then append details
                    messages.push(
                        msg +
                            ([".", "?", "!", ";", ":"].indexOf(msg.substring(msg.length - 1)) > -1 ? "" : ".") +
                            " Details: " +
                            detail,
                    );
                }
            }
        }

        return S25Util.array.forceArray((messages.length > 0 && messages) || msg || defaultText);
    }

    public static errorText(s25Resp: any, defaultText?: string): string {
        let msg = "",
            json: any = null;
        if (!s25Resp) {
            msg = defaultText;
        } else if (typeof s25Resp === "string" && s25Resp.indexOf("</") === -1) {
            //string
            msg = s25Resp;
        } else if (
            typeof s25Resp === "object" &&
            (!s25Resp.data || !s25Resp.data.indexOf || s25Resp.data.indexOf("</") === -1)
        ) {
            //json
            json = s25Resp;
        } else {
            //xml
            json = s25Resp && s25Resp.data && S25Util.prettyConv(s25Resp.data);
        }

        if (json) {
            let errorData = json;
            if (errorData.data) {
                errorData = errorData.data;
            }

            if (!msg) {
                msg =
                    S25Util.propertyGet(errorData, "msg") ||
                    S25Util.propertyGet(errorData, "error_msg") ||
                    S25Util.propertyGet(errorData, "err_msg") ||
                    S25Util.propertyGet(errorData, "message");
            }

            //json.message may be special try/catch error property skipped by propertyGet due to Error not having a toJSON property for serialization
            if (!msg) {
                msg = json.message || defaultText;
            }

            let details =
                S25Util.propertyGet(errorData, "proc_error") ||
                S25Util.propertyGet(errorData, "procError") ||
                S25Util.propertyGet(errorData, "error_detail");
            if (msg && details) {
                msg = msg.trim();
                details = S25Util.isString(details) ? details.trim() : details.content && details.content.trim();

                let objectName = S25Util.propertyGet(errorData, "object_name");
                if (objectName) {
                    details +=
                        ([".", "?", "!", ";", ":"].indexOf(details.substring(details.length - 1)) > -1 ? "" : ".") +
                        " Object: " +
                        objectName;
                }

                //add punctuation to msg, then append details
                msg +=
                    ([".", "?", "!", ";", ":"].indexOf(msg.substring(msg.length - 1)) > -1 ? "" : ".") +
                    " Details: " +
                    details;
            }
        }

        return msg || defaultText;
    }

    public static showError(s25Resp: any, defaultText?: string) {
        console.error(s25Resp);
        if (defaultText && typeof defaultText !== "string") {
            defaultText = "";
        }
        let msg = S25Util.errorText(s25Resp, defaultText);
        msg && alert(msg);
    }

    public static isObject(value: any): boolean {
        return (value && value.constructor && value.constructor === Object) || (value && typeof value === "object");
    }

    public static isFunction(fn: any): boolean {
        return typeof fn === "function";
    }

    public static isValidEmail(emailString: string): boolean {
        //ported from BB file modals.xml, used to validate contact email
        return (
            !!emailString &&
            /^[A-Z0-9\~\!\`\|\$\%\*\/\=\?\^\{\}\#\&\'\.\_\+\-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailString)
        );
    }

    public static isValidUrl(urlString: string): boolean {
        try {
            new URL(urlString);
            return true;
        } catch (err) {
            return false;
        }
    }

    public static isInvalidEmail(email: string): boolean {
        return !S25Util.isValidEmail(email);
    }

    public static uglifyJson(json: any, prefix?: string, attrMap?: any, skipMap?: any): any {
        let retJson: any = {};
        jSith.forEach(json, function (key: any, obj: any) {
            //if key starts with _, its an attribute so dont touch, same with if key contains :, or key obj/arr has __prefix child, or key obj is fun,
            //or key is in attr map, or key is in skip map. If none of those are true, then prefix key with prefix like "r25:{{key}}", else just use key
            var newKey =
                ((key.indexOf("_") !== 0 &&
                    key.indexOf(":") === -1 &&
                    (S25Util.isUndefined(json[key]) ||
                        (S25Util.isDefined(json[key]) &&
                            !json[key].__prefix &&
                            (S25Util.isUndefined(json[key][0]) || !json[key][0].__prefix))) &&
                    typeof json[key] !== "function" &&
                    (!attrMap || !attrMap[key]) &&
                    (!skipMap || !skipMap[key]) &&
                    prefix) ||
                    "") + key;
            if (!skipMap || !skipMap[newKey]) {
                //skip if in skip map
                if (attrMap && attrMap[newKey]) {
                    //if in attr map add _, so that the xml transform will make it an attr
                    newKey = "_" + newKey;
                }
                retJson[newKey] = json[key];
                if (S25Util.array.isArray(json[key])) {
                    for (var i = 0; i < json[key].length; i++) {
                        retJson[newKey][i] = S25Util.uglifyJson(json[key][i], prefix, attrMap, skipMap);
                    }
                } else if (S25Util.date.isDate(json[key])) {
                    retJson[newKey] = S25Util.date.toS25ISODateTimeStr(json[key]);
                } else if (S25Util.isObject(json[key])) {
                    retJson[newKey] = S25Util.uglifyJson(json[key], prefix, attrMap, skipMap);
                }
            }
        });
        return retJson;
    }

    public static s25Json2Xml(json: any, prefix?: string): string {
        prefix = S25Util.coalesce(prefix, "r25:");
        return S25Util.json2Xml(
            S25Util.uglifyJson(
                json,
                prefix,
                { nil: "true", engine: true, pubdate: true, id: true, crc: true, status: true, href: true },
                { r25: true, xl: true, xsi: true },
            ),
        );
    }

    public static prettyConv<T = any>(xml: string, customKeyMap?: any, isArrayMap?: any): T {
        return S25Util.prettifyJson(S25Util.xml.xml_str2json(xml), customKeyMap, isArrayMap);
    }

    public static prettifyJson(json: any, customKeyMap?: any, isArrayMap?: any) {
        //function to prettify xml to json conversions
        customKeyMap = customKeyMap || {};
        let ret: any = {};
        for (let key in json) {
            if (json.hasOwnProperty(key)) {
                let prettyKey = key;
                if (prettyKey.indexOf(":") > -1) {
                    prettyKey = prettyKey.substring(prettyKey.indexOf(":") + 1);
                }
                prettyKey = prettyKey.replace("__", "");
                if (prettyKey[0] === "_") {
                    prettyKey = prettyKey.substring(1);
                }
                prettyKey = customKeyMap[prettyKey] || prettyKey; //user can specify a map of xml2json keys to keys they prefer
                if (prettyKey !== "prefix") {
                    if (isArrayMap && isArrayMap[prettyKey] && !S25Util.array.isArray(json[key])) {
                        json[key] = [json[key]];
                    }

                    if (S25Util.array.isArray(json[key])) {
                        ret[prettyKey] = [];
                        for (let i = 0; i < json[key].length; i++) {
                            ret[prettyKey].push(S25Util.prettifyJson(json[key][i], customKeyMap, isArrayMap));
                        }
                    } else if (json[key] && S25Util.isObject(json[key])) {
                        json[key].__prefix = undefined;
                        if (!S25Util.isEmptyBean(json[key])) {
                            if (json[key] && json[key].__text) {
                                ret[prettyKey] = json[key].__text;
                            } else {
                                ret[prettyKey] = S25Util.prettifyJson(json[key], customKeyMap, isArrayMap);
                            }
                        }
                    } else {
                        ret[prettyKey] = json[key];
                    }
                }
            }
        }
        return ret;
    }

    public static propertyGet(json: any, property: string) {
        return S25Util.propertyFind(json, property, true);
    }

    public static propertyGetVal(json: any, property: string) {
        return S25Util.coalesce(S25Util.propertyGet(json, property), "");
    }

    public static propertyGetAll(json: any, property: string) {
        let resultArr: any[] = [];
        S25Util.propertyFind(json, property, false, resultArr);
        return resultArr;
    }

    public static propertyGetAllUnique(json: any, property: string) {
        return S25Util.array.unique(S25Util.propertyGetAll(json, property));
    }

    public static propertyDelete(json: any, property: any) {
        property = S25Util.array.forceArray(property);
        for (let i = 0; i < property.length; i++) {
            S25Util.propertyFind(json, property[i], null, null, function (json: any, key: any) {
                delete json[key];
            });
        }
    }

    public static propertyDeleteStartsWith(json: any, startsWithProperty: any) {
        // note the empty [] array for "resultArr" -- that causes propertyFind to accumulate results instead of short-circuit at first one found so that we delete ALL matches
        S25Util.propertyFind(
            json,
            startsWithProperty,
            null,
            [],
            function (json: any, key: any) {
                delete json[key];
            },
            null,
            null,
            true,
        ); //true for startsWith
    }

    public static propertyDeleteByValue(json: any, property: any, value: any) {
        property = S25Util.array.forceArray(property);
        for (let i = 0; i < property.length; i++) {
            //empty resultArr to delete ALL matches
            S25Util.valueFindAction(json, property[i], value, [], function (json: any, key: any) {
                delete json[key];
            });
        }
    }

    public static propertyGetParentWithChildValue(json: any, property: string, value: any) {
        let parent: any = null;
        S25Util.propertyFind(
            json,
            property,
            null,
            null,
            function (json: any) {
                parent = json;
            },
            value,
        );
        return parent;
    }

    public static propertyGetParentsWithChildValue(json: any, property: string, value: any) {
        let ret: any[] = [];
        S25Util.propertyFind(
            json,
            property,
            null,
            [],
            function (json: any) {
                ret.push(json);
            },
            value,
        );
        return ret;
    }

    public static valueFindAction(json: any, property: any, value: any, resultArr?: any, action?: any) {
        return S25Util.propertyFind(json, property, null, resultArr, action, S25Util.coalesce(value, ""));
    }

    public static valueFind(json: any, property: any, value: any): boolean {
        return S25Util.valueFindAction(json, property, value, null, null);
    }

    //breadth first search for property; if given value then also property value; if resultArr accumulate findings; if actionOnResult run fn on findings
    public static propertyFind(
        json: any,
        property: string,
        retValue?: boolean,
        resultArr?: any[],
        actionOnResult?: Function,
        value?: any,
        cache?: any[],
        startsWith?: boolean,
    ) {
        let ret: any = undefined,
            queue: any[] = [];
        cache = cache || [];

        if (json) {
            queue.push(json);
            while (queue.length > 0) {
                var item = queue.shift();
                if (item && item.hasOwnProperty) {
                    for (var key in item) {
                        if (item.hasOwnProperty(key)) {
                            //if match...
                            if (
                                (key === property || (startsWith && key.startsWith && key.startsWith(property))) &&
                                (S25Util.isUndefined(value) || item[key] == value)
                            ) {
                                actionOnResult && actionOnResult(item, key);
                                if (resultArr) {
                                    //only add if either NOT an object or if so, if we haven't already processed it
                                    //we add to cache below so the first match is not already in the cache
                                    if (!S25Util.isObject(item[key]) || cache.indexOf(item[key]) === -1) {
                                        if (S25Util.array.isArray(item[key])) {
                                            //if array itself, flatten into resultArr
                                            Array.prototype.push.apply(resultArr, item[key]);
                                        } else {
                                            resultArr.push(item[key]);
                                        }
                                    }
                                } else {
                                    return retValue ? item[key] : true;
                                }
                            } else if (S25Util.isObject(item[key])) {
                                //process children
                                //do not process dollar prefixed keys, window object, scope objects, html and jquery elements
                                //note that checking at the branch expansion point in BFS allows us to still match on any
                                //of these keys and add to results / return from the fn. All these checks do is ensure that
                                //we do not EXPAND into these items bc they are problematic, very deep, and we'd never search on them
                                if (
                                    (key &&
                                        key.indexOf &&
                                        //allow $$state so we can search promise values
                                        key !== "$$state" &&
                                        // skip $ or __zone_ prefixed keys
                                        (key.indexOf("$") === 0 || key.indexOf("__zone_") === 0)) ||
                                    (item[key] &&
                                        (S25Util.isFunction(item[key]) ||
                                            item[key].elementRef || // do not recurse into Angular components
                                            item[key].constructor === Window ||
                                            (window.angBridge &&
                                                window.angBridge.$rootScope &&
                                                item[key].constructor === window.angBridge.$rootScope.constructor) ||
                                            item[key] instanceof HTMLElement ||
                                            (typeof window.jQuery === "function" &&
                                                item[key] instanceof window.jQuery))) ||
                                    //skip circular reference
                                    cache.indexOf(item[key]) > -1
                                ) {
                                    continue;
                                }

                                //if we reach here (continue skips and starts next iter), then we push to BFS queue
                                queue.push(item[key]);
                            }

                            //add objects to cache so we can check for circular references
                            S25Util.isObject(item[key]) && cache.push(item[key]);
                        }
                    }
                }
            }
            return ret;
        } else {
            return ret;
        }
    }

    public static toItemIds(arr: any[]): number[] {
        return arr.map(function (obj: any) {
            return S25Util.parseInt(obj.itemId);
        });
    }

    public static stringify(json: any, cache?: any[]): string {
        if (["number", "string", "boolean"].indexOf(typeof json) > -1) {
            return json;
        } else if (S25Util.date.isDate(json)) {
            return S25Util.date.toS25ISODateTimeStr(json);
        }

        function prefix(isOuterArray: boolean, key: any) {
            return isOuterArray ? "" : '"' + key + '":';
        }

        let ret = "";
        cache = cache || [];
        if (json) {
            let keys = Object.keys(json);
            let isOuterArray = S25Util.array.isArray(json);
            let childStrings: string[] = [];
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let val = json[key];

                if (S25Util.isObject(val)) {
                    if (S25Util.refEq(val, cache)) {
                        //if object ref already in cache, skip
                        continue;
                    } else {
                        //push object ret to cache
                        cache.push(val);
                    }
                }

                //skip scope, dollar, window, HTMLElement, jQuery
                if (
                    (key && key.indexOf && key.indexOf("$") === 0) ||
                    (val &&
                        (val.constructor === Window ||
                            val.constructor === window.angBridge.$rootScope.constructor ||
                            val instanceof HTMLElement ||
                            (typeof window.jQuery === "function" && val instanceof window.jQuery)))
                ) {
                    continue;
                }

                if (S25Util.date.isDate(val)) {
                    childStrings.push(prefix(isOuterArray, key) + JSON.stringify(val));
                } else if (S25Util.isFunction(val)) {
                    childStrings.push(prefix(isOuterArray, key) + "FUNCTION");
                } else if (S25Util.array.isArray(val)) {
                    //loop array
                    childStrings.push(prefix(isOuterArray, key) + S25Util.stringify(val, cache));
                } else if (S25Util.isObject(val)) {
                    //recur obj
                    childStrings.push(prefix(isOuterArray, key) + S25Util.stringify(val, cache));
                } else {
                    //handle key/val
                    childStrings.push(prefix(isOuterArray, key) + JSON.stringify(val));
                }
            }

            childStrings.sort(); //sort child strings for deterministic comparisons
            ret += childStrings.join(",");

            return (isOuterArray ? "[" : "{") + ret + (isOuterArray ? "]" : "}");
        } else {
            return ret;
        }
    }

    public static dfs(
        obj: any,
        func: (node: any, parentNode?: any, childKey?: any) => any,
        childrenProp?: string,
        visited?: any,
        parentNode?: any,
        childKey?: any,
    ) {
        visited = visited || [];
        let isArray = S25Util.array.isArray(obj);
        let isObject = !isArray && S25Util.isObject(obj);

        if (isArray || isObject) {
            if (visited.indexOf(obj) > -1) {
                return;
            } else {
                visited.push(obj);
            }
        }

        obj && func(obj, parentNode, childKey);

        let children = [];
        if (childrenProp) {
            children = (obj && obj[childrenProp] && S25Util.array.forceArray(obj[childrenProp])) || [];
        } else if (isArray || isObject) {
            children = obj;
        }

        jSith.forEach(children, (key: any, val: any) => {
            S25Util.dfs(val, func, childrenProp, visited, obj, key);
        });
    }

    public static bsf(obj: any, func: (node: any, parentNode?: any, childKey?: any) => any, childrenProp?: string) {
        let visited: any[] = [];
        let queue: any[] = [];
        obj &&
            queue.push({
                parentNode: null,
                childKey: null,
                node: obj,
            });

        while (queue.length) {
            let entry = queue.shift();

            let isArray = S25Util.array.isArray(entry.node);
            let isObject = !isArray && S25Util.isObject(entry.node);

            if (isArray || isObject) {
                if (visited.indexOf(entry.node) > -1) {
                    return;
                } else {
                    visited.push(entry.node);
                }
            }

            entry && func(entry.node, entry.parentNode, entry.childKey);

            let children = [];
            if (childrenProp) {
                children =
                    (entry.node && entry.node[childrenProp] && S25Util.array.forceArray(entry.node[childrenProp])) ||
                    [];
            } else if (isArray || isObject) {
                children = entry.node;
            }

            jSith.forEach(children, (key: any, val: any) => {
                queue.push({
                    parentNode: entry.node,
                    childKey: key,
                    node: val,
                });
            });
        }
    }

    public static replaceDeep(json: any, replaceValueMap: any): any {
        jSith.forEach(json, function (key: any, obj: any) {
            if (typeof json[key] !== "object" || S25Util.date.isDate(json[key])) {
                if (S25Util.isDefined(replaceValueMap[key])) {
                    if (S25Util.isFunction(replaceValueMap[key])) {
                        json[key] = replaceValueMap[key](json[key], json); //pass value and parent to function
                    } else {
                        json[key] = replaceValueMap[key]; //replace value with map value
                    }
                }
            } else if (S25Util.array.isArray(json[key])) {
                S25Util.replaceDeep(json[key], replaceValueMap);
            } else if (typeof json[key] === "object") {
                S25Util.replaceDeep(json[key], replaceValueMap); //recurse through object
            }
        });
        return json; //return replaced json
    }

    //removeUndefined deleteUndefined removeNull deleteNull
    public static deleteUndefDeep(json: any): any {
        jSith.forEach(json, function (key: any, obj: any) {
            if (S25Util.isUndefined(json[key])) {
                delete json[key];
            } else {
                if (typeof json[key] === "object" && !S25Util.date.isDate(json[key])) {
                    S25Util.deleteUndefDeep(json[key]);
                }
            }
        });
        return json; //return replaced json
    }

    public static instanceId = S25Const.instanceId;
    public static instanceIdWithPrefix = S25Const.instanceIdWithPrefix;

    public static isIE(): boolean {
        return S25Util.isIEBrowser;
    }

    public static isUnsupportedBrowser(): boolean {
        return S25Util.isIENotEdge;
    }

    public static rsrvTypeMap(type: string | number): string {
        type = S25Util.toStr(type);
        let types: any = {
            "0": "event",
            "1": "event",
            "5": "event",
            "2": "blackout",
            "3": "closed",
            "4": "pending",
        };
        return types[type];
    }

    public static hexToRGB(hex: string): { r: number; g: number; b: number } {
        let bigint: number = 0;
        return { r: ((bigint = parseInt(hex, 16)) >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
    }

    public static rgbToHex(r: number, g: number, b: number): string {
        return S25Util.componentToHex(r) + S25Util.componentToHex(g) + S25Util.componentToHex(b);
    }

    public static rgbStringToRGB(colorString: string): any {
        let arr: string[] = colorString.replace("rgb(", "").replace(")", "").replace(" ", "").split(",");
        return { r: S25Util.parseInt(arr[0]), g: S25Util.parseInt(arr[1]), b: S25Util.parseInt(arr[2]) };
    }

    public static rgbStringToHex(colorString: string): string {
        let rgb = S25Util.rgbStringToRGB(colorString);
        return S25Util.rgbToHex(rgb.r, rgb.g, rgb.b);
    }

    public static colorBrightness(hex: string): number {
        //http://www.blitzbasic.com/Community/posts.php?topic=87307
        var rgb = S25Util.hexToRGB(hex);
        return Math.sqrt(
            S25Util.parseFloat(rgb.r * rgb.r) * 0.241 +
                S25Util.parseFloat(rgb.g * rgb.g) * 0.691 +
                S25Util.parseFloat(rgb.b * rgb.b * 0.068),
        ); //measure of brightness from 0-255, higher is brighter
    }

    public static isTruthy(obj: any): boolean {
        return !!obj;
    }

    public static extend(target: any, ...sources: any): any {
        return Object.assign(target, ...sources);
    }

    // extend JS object like CSS
    public static extendDeep(dst: any, ...args: any): any {
        dst = S25Util.coalesce(dst, {});
        jSith.forEach(args, function (_: any, obj: any) {
            if (obj && obj !== dst) {
                jSith.forEach(obj, function (key: any, value: any) {
                    //note: we do not descend into arrays, etc so do not use S25Util.isObject as it uses typeof and arrays are typeof "object"
                    if (dst[key] && dst[key].constructor && dst[key].constructor === Object) {
                        S25Util.extendDeep(dst[key], value);
                    } else {
                        dst[key] = value;
                    }
                });
            }
        });
        return dst;
    }

    public static split(str: string, delim: string): string[] {
        return str.split(delim);
    }

    public static cloneElement(element: any) {
        let el = jSith.singleElement(element);
        return el && el.cloneNode(true);
    }

    public static simulateElememt(element: any, selectorsForStyleCopy?: string[]) {
        let jEl = jSith.singleElement(element);
        let el = S25Util.cloneElement(jEl);
        if (el) {
            if (document.defaultView && document.defaultView.getComputedStyle) {
                el.style.cssText = document.defaultView.getComputedStyle(jEl, "").cssText || el.style.cssText;
            }

            // note: remove any element-set display style, so CSS display will be used instead (useful when element sets display: none)
            jSith.setCss(el, {
                display: "",
                "min-width": "0px",
                "min-height": "0px",
                position: "absolute !important",
                top: "-9999px !important",
                left: "-9999px !important",
                visibility: "hidden !important",
            });

            if (selectorsForStyleCopy && selectorsForStyleCopy.length) {
                jSith.forEach(selectorsForStyleCopy, function (_, selector) {
                    let subJEls = jSith.find(jEl, selector);
                    let subSimEls = jSith.find(el, selector);

                    jSith.forEach(subJEls, function (i, subJEl) {
                        let subSimEl = subSimEls[i];
                        if (subJEl && subSimEl && document.defaultView && document.defaultView.getComputedStyle) {
                            subSimEl.style.cssText =
                                document.defaultView.getComputedStyle(subJEl, "").cssText || subSimEl.style.cssText;
                        }
                    });
                });
            }

            jSith.append(document.body, el);
            return el;
        }
    }

    // clone element, append to body, measure dimensions, and remove from body
    public static measureElement(element: any): any {
        let el = S25Util.simulateElememt(element);
        if (el) {
            let result = {
                offsetWidth: jSith.outerWidth(el),
                offsetHeight: jSith.outerHeight(el),
            };
            jSith.remove(el);
            return result;
        }
    }

    public static clone(obj: any) {
        return S25Util.copy({}, S25Util.deepCopy(obj));
    }

    public static copy(dst: any, src: any) {
        let isArray: boolean = S25Util.array.isArray(dst);

        if (dst === src) {
            throw Error("Destination equals source");
        } else {
            let cp = S25Util.deepCopy(src);

            //remove all entries / properties from dst
            jSith.forEach(
                dst,
                function (key: any, value: any) {
                    if (isArray) {
                        dst.splice(key, 1);
                    } else {
                        delete dst[key];
                    }
                },
                true,
            );

            //add each entry / property from copy of source into dst
            jSith.forEach(cp, function (key: any, value: any) {
                if (isArray) {
                    dst.push(value);
                } else {
                    dst[key] = value;
                }
            });

            return dst;
        }
    }

    public static date = {
        current: function (): Date {
            return new Date();
        },

        currentDate: function (): Date {
            return S25Util.date.getDate(S25Util.date.current());
        },

        currentDateTime: function (): Date {
            let dateTime = S25Util.date.current();
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
            return dateTime;
        },

        parseFuzzyTimeString: function (timeString: string, useAllHours?: boolean, defaultNow?: boolean): Date {
            if (timeString === "") return null;

            let time = timeString.match(/(\d+)(:(\d\d))?\s*([ap]?)/i);
            if (time == null) return null;

            let hours = S25Util.parseInt(time[1]);
            let minutes = S25Util.parseInt(time[3]) || 0;

            //parse 24 hours in the form of 1400, 1530, 830, where no : is given
            if (hours >= 100 && timeString.indexOf(":") === -1) {
                minutes = hours % 100;
                minutes = minutes > 59 ? 59 : minutes;
                hours = Math.floor(hours / 100);
            }

            let isPM = false;

            if (!time[4]) {
                //unknown am/pm signal, make assumption based off hours, if useAllHours == true do not make assumption -implemented for pricing rate schedules -TW
                isPM = hours >= 12 || (!useAllHours && [1, 2, 3, 4, 5].indexOf(hours) > -1); //note noon is PM
            } else {
                isPM = time[4] === "p" || time[4] === "P";
            }

            if (hours < 12 && isPM) {
                //hours less than 12 and we are in PM, add 12 to get 24-hour time for hours
                hours += 12;
            } else if (hours === 12 && !isPM) {
                //hours is 12 but we are in AM, set hours to 0 to get proper 24-hour time for hours
                hours = 0;
            }

            let d = defaultNow ? new Date() : new Date(1970, 1, 1, 0, 0, 0);
            d.setHours(hours);
            d.setMinutes(minutes);
            d.setSeconds(0);
            d.setMilliseconds(0);
            return d;
        },

        dowInt: {
            SU: 0,
            MO: 1,
            TU: 2,
            WE: 3,
            TH: 4,
            FR: 5,
            SA: 6,
            U: 0,
            M: 1,
            T: 2,
            W: 3,
            R: 4,
            F: 5,
            S: 6,
        } as Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6>,

        dowIntToChar: {
            0: "U",
            1: "M",
            2: "T",
            3: "W",
            4: "R",
            5: "F",
            6: "S",
        } as const,

        dowSort: function (a: any, b: any): -1 | 0 | 1 {
            if (S25Util.date.dowInt[a] > S25Util.date.dowInt[b]) {
                return 1;
            } else if (S25Util.date.dowInt[a] < S25Util.date.dowInt[b]) {
                return -1;
            } else {
                return 0;
            }
        },

        toDow: function (date: Date, dow: string, direction: "sameWeek" | "backward" | "forward"): Date {
            let dowInt = S25Util.coalesce(S25Util.date.dowInt[dow], date.getDay());
            let diff = dowInt - date.getDay();
            if (direction === "forward" && diff < 0) {
                diff += 7;
            } else if (direction === "backward" && diff > 0) {
                diff -= 7;
            }
            return S25Util.date.addDays(date, diff);
        },

        createDate: function (year: any, month: any, day: any): Date {
            return new Date(S25Util.parseInt(year), S25Util.parseInt(month) - 1, S25Util.parseInt(day));
        },

        hourMinuteString: function (date: any): string {
            if (date && date.getHours) {
                return S25Util.leftPad(date.getHours(), 2) + ":" + S25Util.leftPad(date.getMinutes(), 2) + ":00";
            } else {
                if (typeof date === "string" && /^\d\d:\d\d$/.test(date)) {
                    date += ":00";
                }
                return date;
            }
        },

        isBetween: function (date: any, date1: any, date2: any): boolean {
            date = S25Util.date.parse(date);
            return date > S25Util.date.parse(date1) && date < S25Util.date.parse(date2);
        },

        isBetweenEqual: function (date: any, date1: any, date2: any): boolean {
            date = S25Util.date.parse(date);
            return date >= S25Util.date.parse(date1) && date <= S25Util.date.parse(date2);
        },

        clone: function (date: Date | string): Date {
            if (typeof date === "string") {
                date = S25Util.date.parse(date);
            }
            return window.dayjs(date).toDate();
        },

        addYears: function (date: any, years: number): Date {
            return S25Util.date._add(date, years, "year");
        },

        addMonths: function (date: any, months: number): Date {
            return S25Util.date._add(date, months, "month");
        },

        addWeeks: function (date: any, weeks: number): Date {
            return S25Util.date._add(date, weeks, "week");
        },

        addDays: function (date: any, days: number): Date {
            return S25Util.date._add(date, days, "day");
        },

        addHours: function (date: any, hours: number): Date {
            return S25Util.date._add(date, hours, "hour");
        },

        addMinutes: function (date: any, minutes: number): Date {
            return S25Util.date._add(date, minutes, "minute");
        },

        addSeconds: function (date: any, seconds: number): Date {
            return S25Util.date._add(date, seconds, "second");
        },

        toEndOfDay: function (date: any): Date {
            if (!date) {
                return null;
            }
            let clone = S25Util.date.parse(date);
            clone.setHours(23, 59, 59, 0);
            return clone;
        },

        toStartOfDay: function (date: any): Date {
            if (!date) {
                return null;
            }
            let clone = S25Util.date.parse(date);
            clone.setHours(0, 0, 0, 0);
            return clone;
        },

        toStartOfHour: function (date: any): Date {
            if (!date) {
                return null;
            }
            let clone = S25Util.date.parse(date);
            clone.setMinutes(0, 0, 0);
            return clone;
        },

        _add: function (date: any, value: number, durationName: string): Date {
            var d = window.dayjs(date);
            if (value && durationName) {
                d = d.add(S25Util.toFloat(value), durationName);
            }
            return d.toDate();
        },

        copyHourMinute: function (date: any, hours: Date): Date {
            return window
                .dayjs(date)
                .startOf("minute")
                .set("hour", hours.getHours())
                .set("minute", hours.getMinutes())
                .toDate();
        },

        addPuncToDateTimeStr: function <T>(dateStr: T): T {
            if (dateStr && typeof dateStr === "string" && dateStr.match(/^\d+$/)) {
                let newDateStr = "";

                if (dateStr.length >= 4) {
                    newDateStr = dateStr.substr(0, 4);
                }

                if (dateStr.length >= 6) {
                    newDateStr += "-" + dateStr.substr(4, 2);
                }

                if (dateStr.length >= 8) {
                    newDateStr += "-" + dateStr.substr(6, 2);
                }

                if (dateStr.length >= 10) {
                    newDateStr += "T" + dateStr.substr(8, 2);
                }

                if (dateStr.length >= 12) {
                    newDateStr += ":" + dateStr.substr(10, 2);
                }

                if (dateStr.length >= 14) {
                    newDateStr += ":" + dateStr.substr(12, 2);
                }

                return newDateStr as unknown as T;
            }

            return dateStr;
        },

        parse: function (dateStr: string | Date): Date {
            dateStr = S25Util.date.addPuncToDateTimeStr(dateStr);
            if (dateStr && typeof dateStr === "string" && /^\d\d?:\d\d?(?::\d\d?)?\s*(?:am|pm)?$/i.test(dateStr)) {
                return S25Util.date.parseTime(dateStr, true);
            }
            return s25Dateparser.parse(dateStr);
        },

        parseDropTZ: function (dateInput: any): Date {
            if (S25Util.isUndefined(dateInput)) {
                return dateInput;
            }

            let dateStr = "";
            if (S25Util.date.isDate(dateInput)) {
                dateStr =
                    dateInput.getFullYear() +
                    "-" +
                    S25Util.leftPad(dateInput.getMonth() + 1, 2) +
                    "-" +
                    S25Util.leftPad(dateInput.getDate(), 2) +
                    "T" +
                    S25Util.leftPad(dateInput.getHours(), 2) +
                    ":" +
                    S25Util.leftPad(dateInput.getMinutes(), 2) +
                    ":" +
                    S25Util.leftPad(dateInput.getSeconds(), 2);
            } else {
                dateStr = dateInput + "";
            }

            //strip UTC tz
            dateStr = dateStr.replace("Z", "");

            //strip past offset
            let parts: string[] = dateStr.split("-");
            if (parts.length >= 4) {
                //eg, 2015-08-18T11:15:00-04:00
                dateStr = parts[0] + "-" + parts[1] + "-" + parts[2];
            } else {
                //strip future offset
                parts = dateStr.split("+");
                if (parts.length >= 2) {
                    dateStr = parts[0];
                }
            }

            return window.dayjs(dateStr, S25Util.date.timezone._STARTOF_FORMAT.millisecond).toDate();
        },

        dropTZString: function (dateStr: any): string {
            return S25Util.date.toS25ISODateTimeStr(S25Util.date.parseDropTZ(dateStr), true);
        },

        dropTZFromISODateTimeString: function (date: ISODateString) {
            // Only use with ISO date strings starting with yyyy-mm-ddThh:mm:ss
            return date.slice(0, 19);
        },

        parseTime: function (timeStr: string | Date, dropTZ?: boolean): Date {
            let parser = dropTZ ? S25Util.date.parseDropTZ : S25Util.date.parse;

            // string - assume time-only string, so prepend with today's date (regardless of tzoffset)
            if (typeof timeStr === "string") {
                return parser(window.dayjs().format("YYYY-MM-DD") + " " + timeStr);
            }
            // Date object - replace Day with current Day, so the return value will be valid Date() object
            return parser(window.dayjs(timeStr).format("HH:mm:ss"));
        },

        nextUnit: {
            second: "minute",
            minute: "hour",
            hour: "day",
            day: "month",
            month: "year",
        } as const,

        equalTime: function (
            date1: string | Date,
            date2: string | Date,
            unit?: "second" | "minute" | "hour" | "day" | "month",
        ): boolean {
            unit = unit || "second";
            var nextUnit = S25Util.date.nextUnit[unit];
            return (
                window.dayjs(S25Util.date.parseTime(date1)).isSame(S25Util.date.parseTime(date2), unit) &&
                (!nextUnit ||
                    S25Util.date.equalTime(date1, date2, nextUnit as "second" | "minute" | "hour" | "day" | "month"))
            );
        },

        isValidTime: function (time: any): boolean {
            if (!time) {
                return false;
            }
            return S25Util.date.isValid(S25Util.date.parseTime(time));
        },

        getDate: function (date: any): Date {
            var clone = S25Util.date.parse(date);
            clone.setHours(0, 0, 0, 0);
            return clone;
        },

        isValid: function (date: any, allowZeroTime?: boolean): boolean {
            if (!date) {
                return false;
            } else if (S25Util.array.isArray(date)) {
                return false;
            }
            date = jSith.isInt(date) ? jSith.toStr(date) : date;
            date = S25Util.date.parse(date);
            return !(
                !date ||
                !date.toString ||
                date.toString() === "Invalid Date" ||
                !date.getTime ||
                isNaN(date.getTime()) ||
                (date.getTime() <= 0 && !allowZeroTime)
            );
        },

        isDate: function (date: any): date is Date {
            return date instanceof Date;
        },

        toTimeStr: function (date: any, is24?: boolean): string {
            if (!date) {
                return null;
            }
            date = S25Util.date.parse(date);
            if (!S25Util.date.isValid(date, true)) {
                return null;
            }

            return S25Util.date.toTimeStrFromHours(date.getHours() + date.getMinutes() / 60, is24);
        },

        ariaTimeString(timeStr: string): string {
            return timeStr.replace(/\s|0(?=\d:)|:00/g, "").toUpperCase();
        },

        toTimeStrFromHours(hours: number, is24?: boolean): string {
            if (is24) {
                return S25Util.date.toS25ISOTimeStrFromHours(hours).slice(0, 5);
            }

            hours = ((hours % 24) + 24) % 24; // Make sure hours are [0, 24)
            const minutes = Math.round(hours * 60); // Convert to minutes so that mm !== 60
            hours = Math.floor(minutes / 60) % 24;

            const amPm = hours >= 12 ? "pm" : "am";
            if (hours === 0) hours += 12;
            else if (hours >= 13) hours -= 12;

            const hh = String(hours).padStart(2, "0");
            const mm = String(minutes % 60).padStart(2, "0");
            return `${hh}:${mm} ${amPm}`;
        },

        toS25ISOTimeStr: function (date: any): string {
            if (!date) {
                return null;
            }
            date = S25Util.date.parse(date);
            if (!S25Util.date.isValid(date, true)) {
                return null;
            }
            let dateTimeStr = S25Util.date.toS25ISODateTimeStr(date, false); //time str does not need seconds, just hours/minutes
            return dateTimeStr.substring(dateTimeStr.indexOf("T") + 1);
        },

        toS25ISOTimeStrFromDate: function (date: Date, keepSeconds: boolean = false): ISOTimeString {
            if (!date) return null;

            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = keepSeconds ? String(date.getSeconds()).padStart(2, "0") : "00";

            return `${hours}:${minutes}:${seconds}`;
        },

        toS25ISOTimeStrFromHours(hours: number): string {
            hours = ((hours % 24) + 24) % 24; // Make sure hours are [0, 24)
            const minutes = Math.round(hours * 60); // Convert to minutes so that mm !== 60
            const hh = String(Math.floor((minutes / 60) % 24)).padStart(2, "0"); // Mod24 -> hours !== 24
            const mm = String(Math.round(minutes % 60)).padStart(2, "0");
            return `${hh}:${mm}:00`;
        },

        toS25ISODateStr: function (date: any): string {
            let dateString = S25Util.date.toS25ISODateTimeStr(date);
            if (!dateString) {
                return dateString;
            }
            return dateString.substring(0, dateString.indexOf("T"));
        },

        toS25ISODateTimeStr: function (date: any, keepSeconds?: boolean): string {
            if (!date) {
                return null;
            }
            date = S25Util.date.parse(date);
            if (!S25Util.date.isValid(date, true)) {
                return null;
            }
            return S25Util.date.toS25ISODateTimeStrFromDate(date, keepSeconds);
        },

        toS25ISODateTimeStrFromDate: function (date: Date, keepSeconds?: boolean): ISODateString {
            if (!date || isNaN(date.getTime?.())) return;
            !keepSeconds && date.setSeconds(0); //no need for seconds in any place in 25live
            return S25Datefilter.transform(S25Util.date.parseDropTZ(date), S25Dateformat.getISODateTimeFormat());
        },

        toS25ISODateTimeStrNoPunc: function (date: any): string {
            return S25Util.date.toS25ISODateTimeStr(date).replace(/[-:]/g, "");
        },

        toS25ISODateTimeStrNoPuncEndOfDay: function (date: any): string {
            return S25Util.date.toS25ISODateStrEndOfDay(date).replace(/[-:]/g, "");
        },

        toS25ISODateStrStartOfDay: function (date: any): string {
            return date && S25Util.date.toS25ISODateStr(date) + "T00:00:00";
        },

        toS25ISODateStrEndOfDay: function (date: any): string {
            return S25Util.date.toS25ISODateTimeStr(S25Util.date.toEndOfDay(date), true);
        },

        toDateTimeNumeric: function (date: any): string {
            return (S25Util.date.toS25ISODateTimeStr(date) || "").replace(/[\-:]/g, "");
        },

        equal: function (date1: any, date2: any): boolean {
            return date1 && date2 && window.dayjs(date1).isSame(date2);
        },

        equalMonth: function (date1: any, date2: any): boolean {
            //ignore time in date-only comparison
            return date1 && date2 && window.dayjs(date1).isSame(date2, "month");
        },

        equalDate: function (date1: any, date2: any): boolean {
            return date1 && date2 && window.dayjs(S25Util.date.parse(date1)).isSame(S25Util.date.parse(date2), "day");
        },

        equalDates: function (date1: Date, date2: Date): boolean {
            return window.dayjs(date1).isSame(date2);
        },

        getDayOfWeek: function (date: any): number {
            //return day of week (dow)
            return S25Util.date.parse(date).getDay();
        },

        dateToHours: function (date: any): number {
            //return hours and fractional hours (min/60) of a date
            date = S25Util.date.parse(date);
            return date.getHours() + date.getMinutes() / 60;
        },

        timeToHours: function (time: ISOTimeString): number {
            const [h, m, s] = time.split(":").map(Number);
            return h + (m || 0) / 60 + (s || 0) / 3600;
        },

        copy: function (dst: Date, src: Date): Date {
            S25Util.date.copyYearMonthDay(dst, src);
            S25Util.date.copyTime(dst, src);
            return dst;
        },

        copyYearMonthDay: function (dst: Date, src: Date): Date {
            dst.setFullYear(src.getFullYear());
            dst.setMonth(src.getMonth());
            dst.setDate(src.getDate());
            return dst;
        },

        copyTime: function (dst: Date, src: Date): Date {
            dst.setHours(src.getHours());
            dst.setMinutes(src.getMinutes());
            dst.setSeconds(src.getSeconds());
            dst.setMilliseconds(src.getMilliseconds());
            return dst;
        },

        minutesToTime: function (mins: string | number): { day: number; hour: number; minute: number } {
            mins = S25Util.toInt(mins);
            var m = mins % 60,
                m60 = Math.floor(mins / 60),
                h = m60 % 24,
                d = Math.floor(m60 / 24);
            h < 0 && (h = 24 + h);
            m < 0 && (m = 60 + m);
            return { day: d, hour: h, minute: m };
        },

        printDay: function (d: number, forceDay?: boolean): string {
            return d || forceDay ? "(" + (d > 0 ? "+" : "") + d + "d) " : "";
        },

        printTime: function (time: any, timeFormat: string): string {
            let d: number = time.day,
                h: number = time.hour,
                m: number = time.minute;
            let formattedTime: string = S25Datefilter.transform(S25Util.date.parseTime(h + ":" + m), timeFormat);
            return S25Util.date.printDay(d) + formattedTime;
        },

        printMinutes: function (mins: any, timeFormat: string): string {
            return S25Util.date.printTime(S25Util.date.minutesToTime(mins), timeFormat);
        },

        printDurationMinutes: function (mins: any): string {
            let time: any = S25Util.date.minutesToTime(mins),
                d: number = time.day,
                h: number = time.hour,
                m: number = time.minute;
            return S25Util.date.printDuration(d, h, m);
        },

        printDuration: function (days: number, hours: number, minutes: number) {
            const d = days ? `${days}d ` : "";
            const h = hours ? `${hours}h ` : "";
            const m = minutes || (!days && !hours) ? `${minutes}m` : "";
            return d + h + m;
        },

        //https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
        //note these are actually partial months counted as full (used in s25-profile-util)
        diffMonths: function (start: any, end: any): number {
            start = S25Util.date.parse(start); // clone
            end = S25Util.date.parse(end); // clone
            let months: number = (end.getFullYear() - start.getFullYear()) * 12;
            months += end.getMonth() - start.getMonth();
            return months;
        },

        // note: this function purposely drops any TIME from Date object, and compares Days only;
        // i.e. it returns one Day difference for (yesterday 23:59:00, today 00:00:00) - note this is the desired behaviour!
        // [s25-dayflip-time-input, evd-occur-slider-service, evd-occur-service] depend on it
        //   correct day diff, ignoring DST where millisecondsInDay !== 86400000
        // 2070117, Mikhail - tzName is required to get userpreference timezone instead of browser local timezone
        // because dates (start, end) have browser local timezone
        // http://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
        diffDays: function (start: any, end: any): number {
            let millisecondsInDay = 86400000; // 1000*60*60*24
            start = S25Util.date.parse(start); // clone
            end = S25Util.date.parse(end); // clone
            // Discard the time and time-zone information.
            let startUtc = Date.UTC(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                start.getMinutes(),
                start.getSeconds(),
            );
            let endUtc = Date.UTC(
                end.getFullYear(),
                end.getMonth(),
                end.getDate(),
                start.getMinutes(),
                start.getSeconds(),
            );
            return Math.ceil((endUtc - startUtc) / millisecondsInDay);
        },

        diffWeeks: function (start: any, end: any, weekstart?: number): number {
            return (
                (S25Util.date.diffDays(
                    S25Util.date.firstDayOfWeek(start, weekstart),
                    S25Util.date.lastDayOfWeek(end, weekstart),
                ) +
                    1) /
                7
            );
        },

        diffHours: function (startDt: any, endDt: any): number {
            return S25Util.date.diffMinutes(startDt, endDt) / 60;
        },

        diffMinutes: function (startDt: any, endDt: any): number {
            let millisecondsInMinute = 60000;
            return S25Util.toInt(
                (S25Util.date.parse(endDt).getTime() - S25Util.date.parse(startDt).getTime()) / millisecondsInMinute,
            );
        },

        firstDayOfMonth: function (date: any): Date {
            let newDate: Date = S25Util.date.parse(date); //ensure input is date
            return new Date(newDate.getFullYear(), newDate.getMonth(), 1); //year, month, day 1
        },

        lastDayOfMonth: function (date: any): Date {
            return S25Util.date.addDays(S25Util.date.addMonths(S25Util.date.firstDayOfMonth(date), 1), -1);
        },

        firstDayOfWeek: function (date: any, weekstart?: number): Date {
            //weekstart: 0 is Sunday
            weekstart = weekstart || 0;
            let newDate: Date = S25Util.date.parse(date); //ensure input is date
            let d = newDate.getDay(); //get the day of week
            let diff = d - weekstart; //days away from week start
            diff = diff < 0 ? diff + 7 : diff; //if negative days, add 7 so that we rewind below
            return S25Util.date.addDays(newDate, -1 * diff);
        },

        lastDayOfWeek: function (date: any, weekstart?: number): Date {
            return S25Util.date.addDays(S25Util.date.firstDayOfWeek(date, weekstart), 6);
        },

        getDaysInMonth: function (month: number, year: number): number {
            return new Date(year, month, 0).getDate();
        },

        syncDateToDate: function (date1: Date, date2: Date): Date {
            date1.setDate(1); //set date first to a known OK value as when we change year/month, the day might not be valid (eg, 31 in november)
            date1.setSeconds(0);
            date1.setMilliseconds(0);
            date1.setFullYear(date2.getFullYear());
            date1.setMonth(date2.getMonth());
            date1.setDate(date2.getDate());
            return date1;
        },

        syncDateToTime: function (date1: Date, date2: Date): Date {
            date1.setSeconds(0);
            date1.setMilliseconds(0);
            date1.setHours(date2.getHours());
            date1.setMinutes(date2.getMinutes());
            return date1;
        },

        syncDateAll: function (date1: Date, date2: Date): Date {
            S25Util.date.syncDateToDate(date1, date2);
            S25Util.date.syncDateToTime(date1, date2);
            return date1;
        },

        fromToString: function (date: any, endDate: any, dateFormat: string): string {
            if (!endDate || S25Util.date.equalDate(date, endDate)) {
                return S25Datefilter.transform(date, dateFormat);
            }
            return S25Datefilter.transform(date, dateFormat) + " - " + S25Datefilter.transform(endDate, dateFormat);
        },

        suffixDay: function (day: Parameters<typeof S25Util.lastDigit>[0]): string {
            let lastDigit = S25Util.lastDigit(day);
            return day + (lastDigit === 1 ? "st" : lastDigit === 2 ? "nd" : lastDigit === 3 ? "rd" : "th");
        },

        getDayName: function (dow: number): string {
            let dayNames: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return dayNames[dow % dayNames.length]; //circular array, so 0-6, then 7 is 0, 8 is 1, and so on
        },

        getMonthName: function (date: any): string {
            date = S25Util.date.parse(date); //ensure input is date
            let monthNames: string[] = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            return monthNames[date.getMonth()];
        },

        toYMD: function (date: Date): { year?: number; month?: number; day?: number } {
            return date ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : {};
        },

        timezone: {
            convert: function (date: any, tzName: string, format?: string): Date {
                // NOTE: THIS METHOD DOES NOT CONVERT DATES TO A GIVEN TIMEZONE
                // If you need to convert a date use the "convertTo()" method below, which does work.
                // For whatever reason the timezone argument is not used, and this method ends up just cloning the date

                // note: Date is assummed in browser's local timezone
                // note: if no tzName (i.e. no userprefs - return cloned date 'as is', so browser's default timezone will be used
                let d: any = window.dayjs(date);

                // note: intentionally strip offset details and re-create JS Date() with current browser's offset
                let formattedDtStr: string = d.format(format || S25Util.date.timezone._STARTOF_FORMAT.millisecond);

                // parse as Date
                return S25Util.date.parse(formattedDtStr);
            },

            initDayjs: function (): void {
                if (!window.dayjs.tz) {
                    window.dayjs.extend(window.dayjs_plugin_utc);
                    window.dayjs.extend(window.dayjs_plugin_timezone);
                }
            },

            representation: function (
                date: Date,
                timezone: string,
            ): {
                year: number;
                month: number;
                day: number;
                hour: number;
                minute: number;
                second: number;
                millisecond: number;
            } {
                S25Util.date.timezone.initDayjs();
                const d = window.dayjs.tz(date, timezone);
                return {
                    year: d.year(),
                    month: d.month(),
                    day: d.day(),
                    hour: d.hour(),
                    minute: d.minute(),
                    second: d.second(),
                    millisecond: d.millisecond(),
                };
            },

            // Parses a date in the given timezone
            // If date is a JS Date object, then the timezone is discarded and the date and time is parsed in the given timezone
            parse: function (date: string | Date, timezone: string): Date {
                S25Util.date.timezone.initDayjs();
                if (S25Util.date.isDate(date)) date = S25Util.date.dropTZString(date);
                return window.dayjs.tz(date, timezone).toDate();
            },

            // Moves a date to specific time in the specified timezone
            toTime: function (
                date: Date,
                timezone: string,
                hour?: number,
                min?: number,
                sec?: number,
                ms?: number,
            ): Date {
                S25Util.date.timezone.initDayjs();
                let d = window.dayjs(date).tz(timezone);
                if (ms !== undefined) d = d.millisecond(ms);
                if (sec !== undefined) d = d.second(sec);
                if (min !== undefined) d = d.minute(min);
                if (hour !== undefined) d = d.hour(hour);
                return d.toDate();
            },

            // Keep date and time, but convert to a different timezone
            convertTo: function (date: Date, timezone: string): Date {
                S25Util.date.timezone.initDayjs();
                return window.dayjs(date).tz(timezone, true).toDate();
            },

            currentYear: function (tzName: string): Date {
                return S25Util.date.timezone._current(tzName, "year");
            },

            currentMonth: function (tzName: string): Date {
                return S25Util.date.timezone._current(tzName, "month");
            },

            currentDay: function (tzName: string): Date {
                return S25Util.date.timezone._current(tzName, "day");
            },

            currentHour: function (tzName: string): Date {
                return S25Util.date.timezone._current(tzName, "hour");
            },

            currentSecond: function (tzName: string): Date {
                return S25Util.date.timezone._current(tzName, "second");
            },

            nextHour: function (tzName: string): Date {
                return S25Util.date.addHours(S25Util.date.timezone.currentHour(tzName), +1);
            },

            _current: function (
                tzName: string,
                startOf: "year" | "month" | "day" | "hour" | "minute" | "second" | "millisecond",
            ): Date {
                return S25Util.date.timezone.convert(
                    S25Util.date.current(),
                    tzName,
                    S25Util.date.timezone._STARTOF_FORMAT[startOf],
                );
            },

            _STARTOF_FORMAT: {
                year: "YYYY",
                month: "MM",
                day: "YYYY-MM-DD",
                hour: "YYYY-MM-DD HH",
                minute: "YYYY-MM-DD HH:mm",
                second: "YYYY-MM-DD HH:mm:ss",
                millisecond: "YYYY-MM-DD HH:mm:ss.SSS",
            },

            getCurrentTime(timezone: string): {
                year: number;
                month: number;
                day: number;
                hour: number;
                minute: number;
                second: number;
                millisecond: number;
            } {
                return S25Util.date.timezone.representation(new Date(), timezone);
            },

            // Return the number of MS that have passed today
            getDayProgress(timezone: string): number {
                const { hour, minute, second, millisecond } = S25Util.date.timezone.getCurrentTime(timezone);
                return hour * 3_600_000 + minute * 60_000 + second * 1000 + millisecond;
            },
        } as const,

        getGMT: function (date: Date, colon?: boolean): string {
            let gmt = new Date(date).toString().match(/([-\+][0-9]+)\s/)[1];
            colon ? (gmt = gmt.substring(0, 3) + ":" + gmt.substring(3, 5)) : "";
            return gmt;
        },

        /**
         * Creates an object with the DOWs spanned by the start/end dates
         * @param start
         * @param end
         * @param dows
         * @returns dows An object with DOW keys and value true
         */
        getDows: function (start: Date, end: Date, dows: Set<DowChar> = new Set()): Set<DowChar> {
            // Iterate from start to end and find all dows
            while (start < end) {
                const dow = S25Util.date.dowIntToChar[start.getDay() as keyof typeof S25Util.date.dowIntToChar];
                dows.add(dow);
                start = S25Util.date.addHours(start, 20); // Don't add a full day to circumvent any DST issues
            }
            const dow = S25Util.date.dowIntToChar[end.getDay() as keyof typeof S25Util.date.dowIntToChar];
            dows.add(dow);
            return dows;
        },
    };

    public static profileName(profileNameString: string): string {
        return profileNameString && (profileNameString.toLowerCase().indexOf("rsrv_") > -1 ? null : profileNameString);
    }

    public static toJson(obj: any): string {
        return JSON.stringify(obj);
    }

    public static contains(str: string, term: string): boolean {
        return str && str.indexOf(term) !== -1;
    }

    public static endsWith(str: string, term: string): boolean {
        return str && str.indexOf(term, str.length - term.length) !== -1;
    }

    public static isString(obj: any): boolean {
        return obj && typeof obj === "string";
    }

    public static isNumeric(obj: any): boolean {
        return obj && !isNaN(parseFloat(obj)) && isFinite(obj);
    }

    public static coalesceDeep(target: any, ...args: any[]): any {
        return S25Util._merge(true, target, args);
    }

    public static merge(target: any, ...args: any[]): any {
        return S25Util._merge(false, target, args);
    }

    public static _merge(coalesce: boolean, target: any, args: any[]): any {
        target = S25Util.coalesce(target, {});
        jSith.forEach(S25Util.array.forceArray(args), function (_: any, obj: any) {
            //loop all arguments (special js variable for all args passed to function)
            if (obj !== target) {
                //do not re-loop target (first argument)
                jSith.forEach(obj, function (key: any, value: any) {
                    //loop all values in this argument
                    //step through target by argument value's keys, as they should match only at the same recursion level
                    if (
                        S25Util.isObject(target[key]) &&
                        !S25Util.date.isDate(target[key]) &&
                        !S25Util.array.isArray(target[key])
                    ) {
                        //if target at this key is an object, recurse further down
                        S25Util._merge(coalesce, target[key], value);
                    } else if (S25Util.array.isArray(target[key])) {
                        //if target is array, we can't assume value has exact index structure...
                        if (S25Util.array.isArray(value) && value.length) {
                            //value must be array and have values to be added to target
                            //in order to augment target arrays with value arrays, we need some "id" property to only augment the matching object in the array
                            //so we get target and value props ending in "_id" and choose the first property both have in common
                            let targetIds: any[] = []; //target props ending in "_id"
                            let valueIds: any[] = []; //value props ending in "_id"
                            let commonIds: any[] = []; //common props

                            for (let i = 0; i < value.length; i++) {
                                let matchFound = false;
                                let valueArrEntry = value[i];
                                if (i === 0 && S25Util.isObject(valueArrEntry)) {
                                    //only need to do this once as the id prop will be on each entry
                                    valueIds = Object.keys(valueArrEntry).filter(function (k) {
                                        return (
                                            k &&
                                            k.toLowerCase &&
                                            (k.toLowerCase().endsWith("_id") ||
                                                k.toLowerCase().endsWith("address_type"))
                                        );
                                    }); //Address nodes don't include and _id
                                }

                                for (let j = 0; j < target[key].length; j++) {
                                    let targetArrEntry = target[key][j];

                                    if (j === 0 && S25Util.isObject(targetArrEntry)) {
                                        //only need to do this once as the id prop will be on each entry
                                        targetIds = Object.keys(targetArrEntry).filter(function (k) {
                                            return (
                                                k &&
                                                k.toLowerCase &&
                                                (k.toLowerCase().endsWith("_id") ||
                                                    k.toLowerCase().endsWith("address_type"))
                                            );
                                        }); //Address nodes don't include and _id
                                        commonIds = S25Util.array.shallowIntersection(
                                            targetIds,
                                            valueIds,
                                            S25Util.array.unique([].concat(targetIds, valueIds)),
                                        );
                                    }

                                    //if we have common ids, choose the first one, then compare entries based on that id prop
                                    //and if same, merge them
                                    if (commonIds.length) {
                                        let idProp = commonIds[0];
                                        if (targetArrEntry[idProp] == valueArrEntry[idProp]) {
                                            matchFound = true;
                                            S25Util._merge(coalesce, targetArrEntry, valueArrEntry);
                                        }
                                    }
                                }

                                //if we actually had common ids and never found a match, push value entry to target entries as a new entry in target
                                if (!matchFound) {
                                    target[key].push(valueArrEntry);
                                }
                            }
                        }
                    } else {
                        if (coalesce) {
                            target[key] = S25Util.coalesce(target[key], value);
                        } else {
                            target[key] = value;
                        }
                    }
                });
            }
        });
        return target;
    }

    public static falseif(obj: any): any {
        return S25Util.coalesce(obj, false);
    }

    public static undefinedif(obj: any, targetValue: any): any {
        return obj === targetValue ? undefined : obj;
    }

    public static s25CleanupHtml(text: string): string {
        //migrated from shared code in s25-resource-details/details.xsl
        return S25Util.toStr(text)
            .replace(/\n/g, "<br/>")
            .replace(/.lt;/g, String.fromCharCode(60))
            .replace(/.gt;/g, String.fromCharCode(62))
            .trim();
    }

    public static trim(str: string): string {
        return typeof str === "string" ? str.trim() : str;
    }

    // given a number and an array of number sorted in asc order, returns {min:111, max:222} object
    // representing MIN and MAX boundaries for the number
    // where MIN === number and MAX is the smallest value > number or the largest value in the array
    public static findBoundaries(array: any[], number: number): any {
        number = S25Util.toInt(number);

        if (!array || !array.length) {
            return { min: Math.min(0, number), max: Math.max(0, number) };
        } else if (array.length === 1) {
            return { min: Math.min(0, array[0], number), max: Math.max(0, array[0], number) };
        }

        let max: number, min: number;
        // array must be sorted in asc order!
        for (let i = 0; i < array.length; ++i) {
            let value = S25Util.toInt(array[i]);
            if (number < value) {
                max = value;
                min = S25Util.toInt(array[i - 1]);
                break;
            }
        }
        if (S25Util.isUndefined(max)) {
            max = S25Util.toInt(array[array.length - 1]);
            min = S25Util.toInt(array[array.length - 2]);
        }
        return { min: min, max: max };
    }

    public static json: any = {
        jpath: function (jsonIn: any, path: string): any[] {
            //search json with xpath
            let ret: any[] = [];

            let json: any = S25Util.deepCopy(jsonIn);
            json = JSON.parse(JSON.stringify(json).replace(/\$/g, "_"));

            let xmlString: string = "<root>" + S25Util.xml.json2xml_str(json) + "</root>";
            let xmlDoc: any; // = $.parseXML(xmlString);

            if (window.DOMParser) {
                let parser: any = new DOMParser();
                xmlDoc = parser.parseFromString(xmlString, "text/xml");
            } else {
                //IE...
                xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                xmlDoc.async = false;
                xmlDoc.loadXML(xmlString);
            }

            let iterator: any;

            if (document.evaluate) {
                iterator = document.evaluate(path, xmlDoc, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
            } else {
                //IE is terrible...
                iterator = {};
                iterator._results = xmlDoc.selectNodes(path);
                iterator._currentIndex = -1;
                iterator.iterateNext = function () {
                    iterator._currentIndex++;
                    return iterator._results[iterator._currentIndex];
                };
            }

            let result = iterator.iterateNext();
            while (result) {
                var val =
                    S25Util.xml.xml_str2json(result.innerHTML) ||
                    result.innerHTML ||
                    result.xml ||
                    new XMLSerializer().serializeToString(result);
                if (!S25Util.isUndefined(val) && (val.constructor == Object || val.length > 0)) {
                    ret.push(val);
                }
                result = iterator.iterateNext();
            }

            return ret;
        },
    };

    public static directive: any = {
        preCompileStr: function (markup: string): any {
            return window.angBridge.$compile(window.angBridge.element(markup));
        },

        preCompileTmpl: function (tmplId: string): any {
            return S25Util.directive.preCompileStr(window.angBridge.$templateCache.get(tmplId));
        },

        compileTmpl: function (tmplId: string, scope: any): any {
            return S25Util.directive.preCompileTmpl(tmplId)(scope);
        },

        compile: function (element: any, scope: any): any {
            return window.angBridge.$compile(window.angBridge.element(element))(scope);
        },

        applyPreCompiledFn: function (tmplId: string, scope: any, cloneAttachFn: any) {
            let templateFn: any = window.angBridge.$templateCache.get(tmplId);
            templateFn && templateFn(scope, cloneAttachFn);
        },

        appendPreCompiled: function (tmplId: string, scope: any, element: any): any {
            S25Util.directive.applyPreCompiledFn(tmplId, scope, function (clonedElement: any) {
                // jSith.append(element, clonedElement);
                element.append(clonedElement);
            });
        },

        createApiFnRetFn: function (event: any): Function {
            return function (contextScope: any, object: any) {
                contextScope && contextScope.$broadcast(event, object);
            };
        },

        createApiFn: function (name: string, event: any): any {
            let result: any = {};
            // 20161107, Mikhail cannot wrap all createApiFn into $timeout because
            // callers might call $scope.destroy() before we fire
            // and thus we won't be able to destroy child appended to body (qtip)
            result[name] = S25Util.directive.createApiFnRetFn(event);
            return result;
        },

        createApiDigestedFn: function (name: string, event: any): any {
            // 20161107, Mikhail - wrap into $timeout and $scope.digest,
            // so callers don't have to wrap to make sure directive initialized before calling API (i.e. $scope.$broadcast)
            let result: any = {};
            result[name] = function (contextScope: any, object: any) {
                S25Util.directive.timeoutDigest(function () {
                    S25Util.directive.createApiFnRetFn(event)(contextScope, object);
                }, contextScope);
            };
            return result;
        },

        createUpdateFn: function (event: any): any {
            return S25Util.directive.createApiFn("update", event);
        },

        createRefreshFn: function (event: any): any {
            return S25Util.directive.createApiFn("refresh", event);
        },

        createReloadFn: function (event: any): any {
            return S25Util.directive.createApiFn("reload", event);
        },

        createResetFn: function (event: any): any {
            return S25Util.directive.createApiFn("reset", event);
        },

        createInitFn: function (event: any): any {
            return S25Util.directive.createApiDigestedFn("init", event);
        },

        createDestroyFn: function (event: any): any {
            return S25Util.directive.createApiFn("destroy", event);
        },

        // 20160212 - Mikhail - wrap S25LoadingApi.destroy() into $timeout (and into scope.$digest() for consistency)
        // to keep 'loading' messages till Angular DOM Rendering is done, because Angular renders DOM in digest cycle
        // and $timeout ensures digest cycle is done.
        createDestroyYieldRenderingFn: function (event: any): any {
            return {
                destroyYieldRendering: function (contextScope: any, object: any) {
                    // note: cannot call scope.$digest() before scope.$broadcast because occasionally ng-if is not executed if scope.$digest() was already called
                    // thus cannot call createDigestFn() and using $timeout manually
                    // 20160629, Mikhail - have to specify TRUE for $timeout
                    // We have to include and rely on $timeout $rootScope.$digest()
                    // because if $http data is empty (i.e. nothing to render) Angular does not run $digest()
                    // and our $timeout never finishes
                    // http://bugs.collegenet.com/jira/browse/ANG-217
                    window.angBridge.$timeout(
                        S25Util.directive.createDigestFn(function () {
                            S25Util.directive.createDestroyFn(event).destroy(contextScope, object);
                        }, contextScope),
                        0,
                        true,
                    );
                },
            };
        },

        createShowFn: function (event: any): any {
            return S25Util.directive.createApiFn("show", event);
        },

        createHideFn: function (event: any): any {
            return S25Util.directive.createApiFn("hide", event);
        },

        createSetFocusFn: function (event: any): any {
            return S25Util.directive.createApiDigestedFn("setFocus", event);
        },

        createDigestFn: function (fn: Function, scope: any): Function {
            return function () {
                // note: 20160426, Mikhail - Jasmine $timeoutMock does not run rootScope.$apply()
                // however, we have to run rootScope.$apply() (in addition to scope.$digest())
                // because there might be other scopes waiting for us (i.e. s25-directive-deferred)
                return window.angBridge.$timeout(
                    function () {
                        scope && scope.$digest && scope.$digest();
                        return fn && fn();
                    },
                    0,
                    true,
                );
            };
        },

        timeoutDigest: function (fn: Function, scope: any): any {
            return S25Util.directive.createDigestFn(fn, scope)();
        },

        digestPromise: function (fn: Function, scope: any): any {
            // return new Promise(function(resolve, reject) {
            return window.angBridge.$q(function (resolve: Function) {
                let result: any = fn && fn();
                S25Util.directive.timeoutDigest(function () {
                    resolve(result);
                }, scope);
            });
        },

        createDeferredOnInitFn: function (scope: any): Function {
            return function () {
                // only run once
                if (!scope.deferredOnInit) {
                    scope.deferredOnInit = {};
                    // scope.deferredOnInit.promise = new Promise(function(resolve, reject) {
                    scope.deferredOnInit.promise = window.angBridge.$q(function (resolve: Function) {
                        scope.deferredOnInit.resolve = resolve;
                    });
                }
                return scope.deferredOnInit.promise;
            };
        },

        createStateInstance: function (active?: boolean): any {
            let state = {
                active: active || false,

                isActive() {
                    return state.active;
                },

                init() {
                    state.active = true;
                },

                destroy() {
                    state.active = false;
                },
            };

            return state;
        },

        // ng-if is slow and creates flickering / flashing when switching from current displayed data into 'Loading' message
        // i.e. when opening Event Detail / Space Detail from outside link the previous data flashes before 'Loading' appears
        // this State immediately hides the element to avoid flickering
        createElementStateInstance: function ($element: any): any {
            return {
                show() {
                    jSith.removeClass($element, "hidden");
                },

                hide() {
                    jSith.addClass($element, "hidden");
                },
            };
        },
    };

    public static isEmptyBean(obj: any): boolean {
        if (S25Util.isUndefined(obj)) {
            return true;
        }

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let field: any = obj[key];

                if (S25Util.isUndefined(field)) {
                    continue;
                }

                if (typeof field === "string" && field.length > 0) {
                    return false;
                }

                if (typeof field === "number") {
                    return false;
                }

                if (typeof field === "object") {
                    if (!S25Util.isEmptyBean(field)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    public static isNonEmptyBean(obj: any) {
        return !S25Util.isEmptyBean(obj);
    }

    public static isInBoundary(direction: string, gap: number, boundaryValue: number, value: number): boolean {
        if (direction === "left") {
            if (value - gap < boundaryValue) {
                return false;
            }
        } else {
            if (value + gap > boundaryValue) {
                return false;
            }
        }
        return true;
    }

    public static bool: any = {
        isTrue: function (str: any): boolean {
            return !!S25Util.bool.parseBoolean(str);
        },

        // bugs.collegenet.com/jira/browse/SPRING-653
        parseBoolean: function (str: any): any {
            if (typeof str !== "string" && typeof str !== "number") {
                return str;
            }

            let lStr: string = (str + "").toLowerCase();
            return lStr === "true" || lStr === "t" || lStr === "y" || str === "1"
                ? true
                : lStr === "false" || lStr === "f" || lStr === "n" || lStr === "0"
                  ? false
                  : // non-boolean - return as is
                    str;
        },

        toChar: function (value: boolean): "T" | "F" {
            return value ? "T" : "F";
        },
    };

    public static array = {
        //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
        shuffle: function (array: any[]): any[] {
            for (let i = array.length - 1; i > 0; i--) {
                //last element skipped bc no other choices to swap at that point
                let j: number = Math.floor(Math.random() * (i + 1));
                let temp: any = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        },

        sum: arraySum,

        getNumbers: function (num: number): any[] {
            num = isNaN(num) ? 0 : num;
            num = Math.ceil(num);
            if (num <= 0) {
                return [];
            }
            return new Array(num);
        },

        isArray: function (obj: any): boolean {
            return !S25Util.isUndefined(obj) && obj.constructor === Array;
        },

        isEmpty: function (arr: any[]): boolean {
            return !arr || arr.length === 0;
        },

        forceArray: function <T, U = [T] extends [undefined] ? unknown[] : T extends any[] ? T : T[]>(obj: T): U {
            if (!S25Util.array.isArray(obj)) {
                if (S25Util.isUndefined(obj)) {
                    return [] as any;
                } else {
                    return [obj] as any;
                }
            }
            return obj as any;
        },

        propertyListToArray: function (obj: any, justPropValue?: boolean, propName?: string): any[] {
            let ret: any[] = [];
            if (obj) {
                for (let prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        if (prop) {
                            if (justPropValue) {
                                if (obj[prop]) {
                                    let value: any = propName ? obj[prop][propName] : obj[prop];
                                    ret.push(value);
                                }
                            } else {
                                ret.push({ value: obj[prop], prop: prop });
                            }
                        }
                    }
                }
            }

            return ret.sort(S25Util.shallowSort("prop"));
        },

        increment: function (array: any[], step: number): any[] {
            array.forEach(function (val: any, i: number) {
                array[i] = val + step;
            });
            return array;
        },

        isEqual: function (arr1: any[], arr2: any[]): boolean {
            if (!arr1 && !arr2) {
                return true;
            }

            if (!arr1 || !arr2) {
                return false;
            }

            if (arr1.length !== arr2.length) {
                return false;
            }

            for (let i = 0; i < arr1.length; ++i) {
                if (arr1[i] != arr2[i]) {
                    return false;
                }
            }

            return true;
        },

        isEqualDeep: function (arr1: any[], arr2: any[]): boolean {
            return S25Util.array.uniqueDeep([arr1, arr2]).length === 1;
        },

        clone: function (arr: any[]): any[] {
            if (S25Util.isUndefined(arr)) {
                return arr;
            }
            return arr.slice(0);
        },

        insert: function (arr: any[], position: number, value: any) {
            arr.splice(position, 0, value);
        },

        remove: function (arr: any[], position: number) {
            arr.splice(position, 1);
        },

        move: function (arr: any[], fromIndex: number, toIndex: number) {
            if (toIndex || toIndex === 0) {
                let element = arr[fromIndex];
                arr.splice(fromIndex, 1);
                arr.splice(toIndex, 0, element);
            } else {
                S25Util.array.remove(arr, fromIndex);
            }
            return arr;
        },

        injectArray: function (dst: any[], index: number, arrayToInsert: any[]) {
            Array.prototype.splice.apply(dst, [index, 0].concat(arrayToInsert));
        },

        unique: function <T>(arr: T[]): T[] {
            return Array.from(new Set(arr));
        },

        uniqueDeep: function (array: any[]): any[] {
            let orig: any[] = array.concat();
            let checker: any = {};
            let ret: any[] = [];
            for (let i = 0; i < orig.length; i++) {
                let jsonstring = S25Util.stringify(orig[i]); //use json stringify to uniquely identify array value
                if (!checker[jsonstring]) {
                    //hash comparison, very fast
                    checker[jsonstring] = "taken"; //add to hash table
                    ret.push(orig[i]); //add to unique return array
                }
            }
            return ret;
        },

        findByProp: function (arr: any[], prop: any, value: any): number {
            if (arr && arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i][prop] == value) {
                        return i;
                    }
                }
            }
            return -1;
        },

        getByProp: function (arr: any[], prop: any, value: any): any {
            let i: number = S25Util.array.findByProp(arr, prop, value);
            return i > -1 ? arr[i] : null;
        },

        inplaceRemoveByProp: function (arr: any[], prop: any, value: any) {
            let position: number = S25Util.array.findByProp(arr, prop, value);
            if (position === -1) {
                //not found - nothing to remove
                return;
            }
            S25Util.array.remove(arr, position);
        },

        inplacePushByProp: function (arr: any[], prop: any, value: any, valueItem: any) {
            let position: number = S25Util.array.findByProp(arr, prop, value);
            if (position === -1) {
                //not found, so add
                arr.push(valueItem);
                return true;
            }
            return null; //found, so do not add
        },

        inplaceFilter: <T>(arr: T[], filter: (item: T) => boolean, shortCircuit?: boolean) => {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (!filter(arr[i])) {
                    arr.splice(i, 1);
                    if (shortCircuit) return;
                }
            }
        },

        uniqueByProp: function (arr: any[], prop: any): any[] {
            let exists: any = {}; //keep track of what exists in the array
            return arr.filter(function (item: any) {
                if (typeof exists[item[prop]] !== "undefined") {
                    //if it already exists, do not include it again
                    return false;
                } else {
                    //else, add it to the exists list and include it once
                    exists[item[prop]] = true;
                    return true;
                }
            });
        },

        isIn: function (arr: any[], prop: any, value: any): boolean {
            if (!arr || arr.length == 0) {
                return false;
            }

            for (let i = 0; i < arr.length; ++i) {
                let item: any = arr[i];
                if (item[prop] == value) {
                    return true;
                }
            }

            return false;
        },

        isIntDivisor: function (divisor: number, array: any[]): boolean {
            if (S25Util.isUndefined(divisor)) {
                return false;
            }

            for (let i = 0; i < array.length; ++i) {
                if (array[i] % divisor !== 0) {
                    return false;
                }
            }

            return true;
        },

        /**
         * Checks whether two arrays have any intersection. Comparison is made by reference. Short circuits.
         * @param arr1
         * @param arr2
         */
        hasIntersection: function <T>(arr1: T[], arr2: T[]) {
            const arr1Set = new Set(arr1);
            return arr2.some((item) => arr1Set.has(item));
        },

        /**
         * Returns the intersection of two arrays. Comparison is made by reference
         * @param arr1
         * @param arr2
         */
        intersection: function <T>(arr1: T[], arr2: T[]) {
            const arr1Set = new Set(arr1);
            return arr2.filter((item) => arr1Set.has(item));
        },

        //returns all items in arr1 that are also in arr2 by list of supplied properties
        shallowIntersection: function (arr1: any[], arr2: any[], props: any[]): any[] {
            let ret: any = [];
            props = S25Util.array.forceArray(props);
            jSith.forEach(arr1, function (_: any, item1: any) {
                jSith.forEach(arr2, function (_: any, item2: any) {
                    let isIntersect = props.length > 0;
                    jSith.forEach(props, function (_: any, prop: any) {
                        let value1 = S25Util.isObject(item1) ? item1[prop] : item1;
                        let value2 = S25Util.isObject(item2) ? item2[prop] : item2;
                        isIntersect = isIntersect && value1 == value2;
                    });
                    if (isIntersect) {
                        ret.push(item1);
                    }
                });
            });
            return S25Util.array.unique(ret);
        },

        //Combines two arrays, and app applies status properties to the second
        applyStatus: function (src: any[], dst: any[]) {
            src = S25Util.array.forceArray(src);
            dst = S25Util.array.forceArray(dst);

            dst.forEach((item: any) => {
                item.status = "del";
            });

            src.forEach((item) => {
                let obj = S25Util.array.getByProp(dst, "itemId", item.itemId);
                if (obj) {
                    obj.status = "est";
                } else {
                    item.status = "new";
                    dst.push(item);
                }
            });
            return dst;
        },

        last: function <T>(arr: T[]): T {
            return arr.length && arr[arr.length - 1];
        },

        flatten: function <T>(arr: NestedArray<T>, map?: (item: T) => T | NestedArray<T>): T[] {
            if (!arr.reduce) return [arr as unknown as T]; // Not a real array
            return arr.reduce<any>((flat: T[], elem: T | NestedArray<T>) => {
                if (!S25Util.array.isArray(elem) && map) elem = map(elem as T); // Map if possible
                if (!S25Util.array.isArray(elem)) return flat.concat([elem as T]);
                return flat.concat(S25Util.array.flatten(elem as NestedArray<T>, map));
            }, []);
        },

        // Breaks overlapping items into "tracks" of non-overlapping items
        getTracks: function <
            Item extends unknown,
            NumProps extends keyof PickByType<Item, number>,
            Start extends NumProps,
            End extends NumProps,
        >(
            items: Item[],
            startProp: Start,
            endProp: End,
            transform?: (item: Item) => Record<Start | End, number>,
        ): Item[][] {
            if (!items.length) return [[]];

            const getVal = (item: Item, prop: Start | End) => (transform ? transform(item) : item)[prop] as number;
            items.sort((a, b) => getVal(a, startProp) - getVal(b, startProp));

            const tracks: Item[][] = [];
            for (let item of items) {
                const itemVal = getVal(item, startProp);
                const track = tracks.find((track) => {
                    const lastItemVal = getVal(track[track.length - 1], endProp);
                    return lastItemVal <= itemVal;
                });
                if (!track) tracks.push([item]);
                else track.push(item);
            }

            return tracks;
        },

        /**
         * Counts the number of items for which the countingFunction returns true
         * @param arr Array of items to count
         * @param countingFunction Callback returning a number for how much to count
         * @return {number}
         */
        count: function <T>(arr: T[], countingFunction: (item: T) => number): number {
            return arr.reduce((count, item) => count + countingFunction(item), 0);
        },

        /**
         * Finds the max depth of an array
         * @param arr Array to find the depth of
         * @param map Function which maps an item to the next array
         * @param filter Function which filters out undesirable paths
         */
        depth: function <T extends any>(arr: T[], map?: (item: T) => T[], filter?: (item: T) => boolean): number {
            if (!S25Util.array.isArray(arr)) return 0;
            return (
                Math.max(
                    0,
                    ...arr.map((item) => {
                        if (filter && !filter(item)) return 0;
                        let itemArr = map ? map(item) : (item as T[]);
                        return S25Util.array.depth(itemArr, map);
                    }),
                ) + 1
            );
        },

        /**
         * Groups array items by keys provided by the passed grouping function
         */
        groupBy: function <T, Prop extends PropertyKey>(
            arr: T[],
            grouper: (item: T) => Prop,
        ): Partial<{ [Key in Prop]: T[] }> {
            const groups = {} as { [Key in Prop]: T[] };
            for (let item of arr) {
                const key = grouper(item);
                if (!groups[key]) groups[key] = [];
                groups[key].push(item);
            }
            return groups;
        },

        /**
         * Reverses an array in place between two indices
         * @param arr The array to be partially reversed
         * @param start {number} The first index to be reversed
         * @param end {number} The last index to be reversed
         * @returns The original array modified in place
         * @time O(n)
         * @space O(1)
         */
        reverse: function <T>(arr: T[], start?: number, end?: number): T[] {
            start = S25Util.clamp(start ?? 0, 0, arr.length - 1);
            end = S25Util.clamp(end ?? arr.length - 1, 0, arr.length - 1);
            while (start < end) {
                [arr[start], arr[end]] = [arr[end], arr[start]]; // Swap
                [start, end] = [start + 1, end - 1]; // Increment/Decrement
            }
            return arr;
        },

        /**
         * Rotates an array in place by "count" steps
         * @param arr The array to be rotated
         * @param count {number} Number of steps to rotate
         * @returns The original array modified in place
         * @time O(3n)
         * @space O(1)
         */
        rotate: function <T>(arr: T[], count: number): T[] {
            count = ((count % arr.length) + arr.length) % arr.length; // (+ arr.length) % arr.length to deal with negative counts
            S25Util.array.reverse(arr); // Reverse whole array
            S25Util.array.reverse(arr, 0, count - 1); // Reverse first part
            S25Util.array.reverse(arr, count); // Reverse second part
            return arr;
        },

        /**
         * Iterates over and enumerates an array
         * @param arr
         */
        enumerate: function* <T>(arr: T[]) {
            if (!arr?.length) return;
            for (const i of S25Util.range(arr.length)) yield [i, arr[i]] as const;
        },
    };

    public static jquery: any = {
        initEvents: function (targets: any, events: any, actionFn: any): any {
            S25Util.jquery._createEvents("on", targets, events, actionFn);
        },

        destroyEvents: function (targets: any, events: any, actionFn: any): any {
            S25Util.jquery._createEvents("off", targets, events, actionFn);
        },

        _createEvents: function (type: string, targets: any, events: any, actionFn: any): any {
            // events=false - special for qtip
            if (!targets || !events || !actionFn) {
                return;
            }

            // because replace above might introduced duplicates - get unique array of strings of events
            let eventsArr: any[] =
                typeof events === "string" ? S25Util.array.unique(S25Util.split(events, " ")) : events;

            jSith.forEach(targets, function (_: any, target: any) {
                target = jSith.element(target);
                jSith.forEach(eventsArr, function (_: any, event: any) {
                    if (event === "enter" || event === "space") {
                        event = "keypress";
                        let origActionFn = actionFn;
                        actionFn = function (e: any) {
                            let keyCode: number = e && parseInt(e.keyCode || e.which);
                            if (keyCode === 13 || keyCode === 32) {
                                e.preventDefault();
                                return origActionFn();
                            }
                        };
                    }

                    if (type === "on") {
                        window.angBridge.element(target).on(event, actionFn);
                        // jSith.on(target, event, actionFn);
                    } else {
                        window.angBridge.element(target).off(event, actionFn);
                        // jSith.off(target, event, actionFn);
                    }
                });
            });
        },
    };

    public static generateQuickGUID(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    public static randomUUID(): string {
        // crypto not available unless httpS, so for local / gauss just fallback to generateQuickGUID
        if (window.crypto?.randomUUID) {
            return window.crypto.randomUUID();
        }
        return this.generateQuickGUID();
    }

    public static toFixed(number: number, digits: number): string {
        let len = number.toString().length,
            power: number = len - digits;

        // number if shorter than requested - return as is
        if (power < 1) {
            return number + "";
        }
        return (number / Math.pow(10, power)).toFixed(0);
    }

    public static bootstrap: any = {
        // bootstrap 4 break points
        // https://v4-alpha.getbootstrap.com/layout/overview/
        /**
         * // Extra small devices (portrait phones, less than 576px)
         // No media query since this is the default in Bootstrap

         // Small devices (landscape phones, 576px and up)
         @media (min-width: 576px) { ... }

          // Medium devices (tablets, 768px and up)
         @media (min-width: 768px) { ... }

          // Large devices (desktops, 992px and up)
         @media (min-width: 992px) { ... }

          // Extra large devices (large desktops, 1200px and up)
         @media (min-width: 1200px) { ... }
         */
        breakpoints: {
            // note: our breakpoints are shift-by-one because bootstrap breaks columns on prev point
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1200,
        },

        getColMaxWidth: function (point: string, numCols: number, maxWidth: number): number {
            if (!point || !numCols) {
                return undefined;
            }

            var screenWidth = window.screen.width;
            var colWidth = Math.ceil(screenWidth / numCols);
            var ptWidth = S25Util.bootstrap.breakpoints[point];

            // largest from (colWidth, ptWidth) but no more than screenWidth
            var bootstrapWidth = Math.min(screenWidth, Math.max(colWidth, ptWidth || 0));

            // if maxWidth provided use it
            return Math.min(maxWidth || Number.MAX_VALUE, bootstrapWidth);
        },
    };

    //https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    public static storageAvailable(type: any) {
        let storage: any;

        try {
            storage = window[type];
        } catch (e) {
            return false;
        }

        let x: string = "__storage_test__";

        try {
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === "QuotaExceededError" ||
                    // Firefox
                    e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    }

    public static localStorageGet(key: string) {
        if (S25Util.storageAvailable("localStorage")) {
            return window.localStorage.getItem(key);
        }
    }

    public static localStorageSet(key: string, str: string) {
        if (S25Util.storageAvailable("localStorage")) {
            return window.localStorage.setItem(key, str);
        }
    }

    public static localStorageClear() {
        if (S25Util.storageAvailable("localStorage")) {
            window.localStorage.clear();
        }
    }

    //cribbed from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    //async is supported everywhere but IE and non-localhost http.
    public static copyToClipboard(text: string) {
        if (!navigator.clipboard) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
            } catch (err) {
                console.error("Fallback: Unable to copy", err);
                alert("The copy function failed to copy the text: " + text);
            }

            document.body.removeChild(textArea);
            return;
        }

        navigator.clipboard.writeText(text).then(
            function () {},
            function (err) {
                alert("The copy function failed to copy the text: " + text);
                console.error("Async: Could not copy text: ", err);
            },
        );
    }

    // Returns a promise for a timeout. Can also be passed a value to resolve the promise with
    public static delay<T>(ms: number, value?: T): Promise<T> {
        return new Promise((resolve) => jSith.timeout(() => resolve(value), ms));
    }

    // Subscribe to changes to an object's property
    // This is useful when AngularJS changes a model's properties outside the Angular zone
    // such that angular cannot detect the change right away
    public static detectPropertyChanges(
        model: { [key: string]: any },
        property: string,
        changeDetector: ChangeDetectorRef,
    ) {
        this.onPropertyChange(model, property, () => changeDetector.detectChanges());
    }

    // Subscribe to changes to an object's property
    // This is useful when AngularJS changes a model's properties outside the Angular zone
    // such that angular cannot detect the change right away
    public static onPropertyChange<T>(
        model: { [key: string]: any },
        property: string,
        callback: (newValue?: T, oldValue?: T) => void,
    ) {
        if (!model) return;
        let value = model[property];

        const oldSet = Object.getOwnPropertyDescriptor(model, property)?.set;
        const oldGet = Object.getOwnPropertyDescriptor(model, property)?.get;

        let set = (newValue: T) => {
            const oldValue = value;
            value = newValue;
            callback(newValue, oldValue);
            oldSet?.(newValue);
        };
        let get = () => {
            oldGet?.();
            return value;
        };

        Object.defineProperty(model, property, {
            configurable: true,
            set(newValue: any) {
                return set(newValue);
            },
            get() {
                return get();
            },
        });
    }

    // Momentarily changes the content of the for loop such that it has to be re-rendered
    public static async refreshNgFor(obj: any, property: string) {
        const data = obj[property];
        obj[property] = [];
        obj[property] = await S25Util.delay(0, data);
    }

    // Returns a function to refresh a specific loop in the template
    public static refreshableNgFor(obj: any, property: string) {
        return () => S25Util.refreshNgFor(obj, property);
    }

    // Clamps a number between a minimum and maximum value
    // Return min if number is smaller than min, return max is number is greater than max
    public static clamp(n: number, min: number, max: number) {
        if (min > max) max = min; // Make sure that max is not smaller than min
        return n < min ? min : n > max ? max : n;
    }

    public static isStatusChanged(node: S25WsNode) {
        return S25Util.isDefined(node?.status) && node?.status !== "est";
    }

    // A monad that takes a promise to resolve to the format [data, error]
    public static Maybe<T>(promise: Promise<T>): Promise<Result<T, any>> {
        if (!promise) return Promise.resolve([promise as T, null]);
        return promise.then(
            (data) => [data, null],
            (error) => [null, error],
        );
    }

    // Create a new promise and return it along with its resolvers
    public static createPromise<T>() {
        let resolve: (value?: T) => void;
        let reject: (reason?: any) => void;
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        return { promise, resolve, reject };
    }

    // Polyfill for Object.fromEntries()
    public static fromEntries<Key extends string | number, T>(entries: [Key, T][]): Record<Key, T> {
        const obj = {} as Record<Key, T>;
        for (let [key, value] of entries) obj[key] = value;
        return obj;
    }

    public static firstCharToLower(str: string) {
        if (!str) return "";
        return str[0].toLowerCase() + str.slice(1);
    }

    public static firstCharToUpper(str: string) {
        if (!str) return "";
        return str[0].toUpperCase() + str.slice(1);
    }

    public static capitalizeWords(str: string) {
        return str.split(/\s+/).map(S25Util.firstCharToUpper).join(" ");
    }

    // Deletes all owned properties from an object.
    // This is handy when you want to replace an object, but keep the reference
    public static clearObject(obj: any) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) delete obj[key];
        }
        return obj;
    }

    public static memoize(func: Function, options?: MemoOptions) {
        return MemoRepository.memoize(func, options);
    }

    public static interpolateColor(index: Proto.Fraction, start: Proto.HexColor, end: Proto.HexColor) {
        if (!index) return start;
        const startRGB = S25Util.hexToRGB(start.replace(/^#/, ""));
        const endRGB = S25Util.hexToRGB(end.replace(/^#/, ""));
        const resultRGB = { r: 0, g: 0, b: 0 };
        for (let c of ["r", "g", "b"] as const)
            resultRGB[c] = Math.round(startRGB[c] + index * (endRGB[c] - startRGB[c]));
        return "#" + S25Util.rgbToHex(resultRGB.r, resultRGB.g, resultRGB.b);
    }

    public static range = range;

    public static pageOffset(elem: HTMLElement) {
        const offset = {
            top: 0,
            left: 0,
        };
        while (elem) {
            offset.top += elem.offsetTop;
            offset.left += elem.offsetLeft;
            elem = elem.offsetParent as HTMLElement;
        }
        return offset;
    }

    // Finds the closest scrollable element
    public static getScrollableContainer(node: any): HTMLElement {
        if (!node || !(node instanceof HTMLElement)) return node;
        const overflowY = window.getComputedStyle(node).overflowY;
        const isScrollable = !(overflowY.includes("hidden") || overflowY.includes("visible"));
        if (isScrollable && node.scrollHeight >= node.clientHeight) return node;
        return this.getScrollableContainer(node.parentNode) || document.body;
    }

    public static loadScript(uniqueName: string, path: string): Promise<boolean> {
        let existingPromise = S25Util.ScriptPromises.get(uniqueName);
        if (existingPromise) {
            return existingPromise;
        }

        let defer = jSith.defer();
        S25Util.ScriptPromises.set(uniqueName, defer.promise);

        let tagName = "script";
        let pathProp = "src";
        let rel = "";
        if (path.endsWith("css")) {
            tagName = "link";
            pathProp = "href";
            rel = "stylesheet";
        }
        const script = document.createElement(tagName);
        if (rel) {
            script.rel = "stylesheet";
        }
        script.onload = () => {
            defer.resolve(true);
        };
        script[pathProp] = path;

        document.head.appendChild(script);

        return defer.promise;
    }

    public static isAppleMail(): boolean {
        if (S25Util.isDefined(S25Util.IsAppleMail)) {
            return S25Util.IsAppleMail;
        }

        let userAgentStr = window.navigator.userAgent;

        // https://github.com/ua-parser/uap-core/blob/master/regexes.yaml (yaml -> json; filtered to just AppleWebKit|Safari up until the Apple Mail entry)
        const userAgentParsers: any = [
            {
                regex: "AppleWebKit/\\d{1,10}\\.\\d{1,10}.{0,200} Safari.{0,200} (CreativeCloud)/(\\d+)\\.(\\d+).(\\d+)",
                family_replacement: "Adobe CreativeCloud",
            },
            {
                regex: "(?:Mobile Safari).{1,300}(OPR)/(\\d+)\\.(\\d+)\\.(\\d+)",
                family_replacement: "Opera Mobile",
            },
            {
                regex: "(rekonq)/(\\d+)\\.(\\d+)(?:\\.(\\d+)|) Safari",
                family_replacement: "Rekonq",
            },
            {
                regex: "AppleWebKit.{1,200} (NX)/(\\d+)\\.(\\d+)\\.(\\d+)",
                family_replacement: "NetFront NX",
            },
            {
                regex: "(iPod|iPhone|iPad).{1,200}Version/(\\d+)\\.(\\d+)(?:\\.(\\d+)|).{1,200}[ +]Safari",
                family_replacement: "Mobile Safari",
            },
            {
                regex: "(iPod|iPod touch|iPhone|iPad);.{0,30}CPU.{0,30}OS[ +](\\d+)_(\\d+)(?:_(\\d+)|).{0,30} AppleNews\\/\\d+\\.\\d+(?:\\.\\d+|)",
                family_replacement: "Mobile Safari UI/WKWebView",
            },
            {
                regex: "(iPod|iPhone|iPad).{1,200}Version/(\\d+)\\.(\\d+)(?:\\.(\\d+)|)",
                family_replacement: "Mobile Safari UI/WKWebView",
            },
            {
                regex: "(iPod|iPod touch|iPhone|iPad).{0,200} Safari",
                family_replacement: "Mobile Safari",
            },
            {
                regex: "(iPod|iPod touch|iPhone|iPad)",
                family_replacement: "Mobile Safari UI/WKWebView",
            },
            {
                regex: "(AppleWebKit)/(\\d+)(?:\\.(\\d+)|)\\+ .{0,200} Safari",
                family_replacement: "WebKit Nightly",
            },
            {
                regex: "(Version)/(\\d+)\\.(\\d+)(?:\\.(\\d+)|).{0,100}Safari/",
                family_replacement: "Safari",
            },
            {
                regex: "(Safari)/\\d+",
            },

            // Apple Mail - not directly detectable, have it after Safari stuff
            {
                regex: "(AppleWebKit)/(\\d+)\\.(\\d+)\\.(\\d+)",
                family_replacement: "Apple Mail",
            },
        ];

        for (let i = 0; i < userAgentParsers.length; i++) {
            let entry = userAgentParsers[i];
            let regex = new RegExp(entry.regex);
            let match = regex.exec(userAgentStr || "");
            if (match) {
                if (entry.family_replacement) {
                    if (entry.family_replacement.indexOf("$1") > -1) {
                        S25Util.IsAppleMail = entry.family_replacement.replace(/\$1/, match[1]) === "Apple Mail";
                    } else {
                        S25Util.IsAppleMail = entry.family_replacement === "Apple Mail";
                    }
                } else {
                    S25Util.IsAppleMail = match[1] === "Apple Mail";
                }
                break;
            }
        }

        if (S25Util.isUndefined(S25Util.IsAppleMail)) {
            S25Util.IsAppleMail = false;
        }

        return S25Util.IsAppleMail;
    }

    /**
     * Returns a promise that resolves when the condition function returns true
     * @param condition
     * @param interval Milliseconds
     */
    public static async waitFor(condition: () => boolean, interval = 1_000): Promise<void> {
        while (!condition()) {
            await S25Util.delay(interval);
        }
    }

    /**
     * Parses a JSON string. If parsing succeeded the data is populated, if there was an error the error is populated
     * @param json String to parse
     * @return An array [data, error]
     */
    public static parseJson<T>(json: string): Result<T, any> {
        try {
            return [JSON.parse(json), null];
        } catch (err: any) {
            return [null, err];
        }
    }
}

// This function is defined outside the class in order to use Typescript overloads
// This lets us provide a more accurate type for the function

function arraySum(arr: unknown[]): number;
function arraySum<T extends { [_ in P]?: unknown }, P extends PropertyKey>(arr: T[], prop: P): number;
/**
 * Sums the items of an array.
 * @param arr An array containing any type
 * @param [prop] If arr contains object, then this property is used to get values out of the objects
 * @return {number}
 */
function arraySum<T>(arr: T[], prop?: PropertyKey): number {
    if (prop) {
        return arr.reduce((sum, item: any) => sum + (S25Util.parseFloat(item?.[prop]) || 0), 0);
    } else {
        return arr.reduce((sum, item) => sum + (S25Util.parseFloat(item) || 0), 0);
    }
}

// This function is defined outside the class in order to use Typescript overloads
// This lets us provide a more accurate type for the function
function range(stop: number): Generator<number, void, unknown>;
function range(start: number, stop: number, step?: number): Generator<number, void, unknown>;
function* range(startOrStop: number, stop: number = null, step = 1) {
    if (typeof stop !== "number") {
        stop = startOrStop;
        startOrStop = 0;
    }
    for (let i = startOrStop; i < stop; i += step) yield i;
}

(() => {
    let onload = (e: Event) => {
        S25Util.viewportWidth = jSith.width(window);
    };

    if (window.addEventListener) {
        window.addEventListener("load", onload);
        window.addEventListener("resize", onload);
    } else if (window.attachEvent) {
        window.attachEvent("onload", onload);
        window.attachEvent("onresize", onload);
    }
})();
